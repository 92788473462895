import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileViewer from "../../shared/FIleViewer";

export const InspectionHistory = ({modalData}) => {
    return (
        <Box sx={{ padding: 2 }}>
            <Typography variant="h5" gutterBottom>
                Inspection History
            </Typography>
            <InspectionAccordion inspectionData={modalData} />
        </Box>
    );
}
const isExpired = (expiryDate) => {
    return new Date(expiryDate) < new Date();
};
const InspectionAccordion = ({ inspectionData }) => {
    return (
        <Box sx={{ marginTop: 2 }}>
            {inspectionData?.inspectionHistory?.map((inspection, index) => (
                <Accordion key={inspection._id} sx={{marginTop:2, }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                        sx={{
                            backgroundColor: isExpired(inspection.expiryDate)
                                ? "#f44336" // Red if canceled
                                :isExpired(inspection.expiryDate)
                                    ? "#007BFF"
                                    : "#66bb6a", // Blue if ended, green otherwise
                            color: "white",
                            boxShadow: 2, // Shadow for header
                            borderRadius: 2, // Rounded corners
                        }}
                    >
                        <Box display="flex" flexDirection="column">
                            <Typography variant="h6">Inspection {index + 1}</Typography>
                            <Box display="flex" flexDirection="row"  gap={2}>
                                {/* Upload Date */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold', color: "white", }}>
                                        Upload Date:
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatDate(inspection.timestamp)}
                                    </Typography>
                                </Box>
                                {/* Expiry Date */}
                                <Box>
                                    <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                        Expiry Date:
                                    </Typography>
                                    <Typography variant="body2">
                                        {formatDate(inspection.expiryDate)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Divider />
                        {/* Report */}
                        {inspection.report ? (
                            <Box>
                                <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                                    Inspection Report:
                                </Typography>
                                <FileViewer fileUrl={inspection.report} />
                            </Box>
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No report available.
                            </Typography>
                        )}
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default InspectionAccordion;
const formatDate = (date) => {
    return date ? new Date(date).toDateString() : "-";
};
