import React, {useEffect, useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Box, Grid, Skeleton, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import CopyWrapper from "./CopyWrapper";

// RideList component to display the list of rides
const RideList = ({id,driver}) => {
    const [rideData, setRideData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch the ride data from the API when the component mounts
        const fetchRideData = async () => {
            try {
                const response = await axios.get(`${driver ? '/driver-rides/' : '/rider-rides/'}${id}`);
                setRideData(response.data.rideData);
            } catch (error) {
                console.error("Error fetching ride data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchRideData();
    }, [id]);

    return (
        <Box sx={{ padding: 1 }}>
            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                Ride List
            </Typography>
            <Grid container spacing={3}>
                {loading ? (
                    // If loading, show skeleton loaders
                    Array(4)
                        .fill(0)
                        .map((_, index) => (
                            <Grid item xs={12} key={index}>
                                <Skeleton variant="rectangular" width="100%" height={120} />
                            </Grid>
                        ))
                ) : rideData.length === 0 ? (
                    // If rideData is empty, show a message
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">
                            No rides available.
                        </Typography>
                    </Grid>
                ) : (
                    rideData.map((ride) => (
                        <Grid item xs={12} key={ride._id}>
                            <Accordion
                                sx={{
                                    backgroundColor:
                                        ride.rideStatus === "Ended" ? "#f5f5f5" : "#e3f2fd", // Set background color based on ride status
                                    boxShadow: 3, // Add shadow to the entire accordion
                                    "&:hover": {
                                        boxShadow: 6, // Increase shadow on hover
                                    },
                                    borderRadius: 2, // Rounded corners
                                }}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon color="white" />} // Add shadow to the expand icon
                                    aria-controls={`panel-${ride._id}-content`}
                                    id={`panel-${ride._id}-header`}
                                    sx={{
                                        backgroundColor: ride.canceled.isCanceled
                                            ? "#f44336" // Red if canceled
                                            : ride.rideStatus === "Ended"
                                                ? "#007BFF"
                                                : "#66bb6a", // Blue if ended, green otherwise
                                        color: "white",
                                        boxShadow: 2, // Shadow for header
                                        borderRadius: 2, // Rounded corners
                                    }}
                                >
                                    {/* Accordion header with pick-up location, status, and fare */}
                                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                                        Pick-up: {ride.pickUpLocationName}
                                    </Typography>
                                    <Typography sx={{ width: "33%", textAlign: "center" }}>
                                        Status: {ride.rideStatus}
                                    </Typography>
                                    <Typography sx={{ width: "33%", textAlign: "right" }}>
                                        Fare: ${ride.fare.toFixed(2)}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails
                                    sx={{
                                        backgroundColor: "#f5f5f5", // Light gray background for the details
                                        boxShadow: 1, // Shadow for the details section
                                        borderRadius: 2, // Rounded corners for the details section
                                    }}
                                >
                                    {/* Accordion content with additional ride details */}
                                    <Typography variant="body2">
                                        Drop-off: {ride.dropOffLocationName}
                                    </Typography>
                                    <Typography variant="body2">
                                        Date: {new Date(ride.createdAt).toLocaleDateString()} at{" "}
                                        {new Date(ride.createdAt).toLocaleTimeString()}
                                    </Typography>
                                    <Typography variant="body2">Ride ID: <CopyWrapper>{ride._id}</CopyWrapper></Typography>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    ))
                )}
            </Grid>
        </Box>
    );

};

export default RideList;
