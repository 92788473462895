import React, {useRef, useState} from "react";
import {Box, Button, Grid, TextField, Typography} from "@mui/material";
import {DrawingManager, GoogleMap, LoadScript} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../constants";
import axios from "axios";
import toast from "react-hot-toast";

const MemoizedGoogleMap = React.memo(({onLoad, onPolygonComplete}) => {
    return (
        <GoogleMap
            mapContainerStyle={{width: "100%", height: "500px"}}
            center={{lat: 37.0902, lng: -95.7129}}
            zoom={4}
            onLoad={onLoad}
            options={{scrollwheel: true}}
        >
            <DrawingManager
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: {drawingModes: ["polygon"]},
                    polygonOptions: {editable: true},
                }}
            />
        </GoogleMap>
    );
});

const DriverAlerts = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [polygonPath, setPolygonPath] = useState([]);
    const [titleError, setTitleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [polygonError, setPolygonError] = useState("");
    const mapRef = useRef();
    const polygonRef = useRef(null);

    const handlePolygonComplete = (polygon) => {
        if (polygonRef.current) polygonRef.current.setMap(null);
        const path = polygon.getPath().getArray().map((latLng) => ({lat: latLng.lat(), lng: latLng.lng()}));
        setPolygonPath(path);
        polygonRef.current = polygon;
        setPolygonError("");

        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({lat, lng}) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
        mapRef.current.fitBounds(bounds);
    };

    const handleRemovePolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
            setPolygonPath([]);
            setPolygonError("");
        }
    };

    const handleSubmit = async () => {
        let valid = true;

        // Field validations
        if (!title.trim()) {
            setTitleError("Title is required");
            valid = false;
        } else {
            setTitleError("");
        }
        if (!description.trim()) {
            setDescriptionError("Description is required");
            valid = false;
        } else {
            setDescriptionError("");
        }
        if (polygonPath.length === 0) {
            setPolygonError("Polygon is required");
            valid = false;
        } else {
            setPolygonError("");
        }

        if (valid) {
            const data = {title, description, coordinates: polygonPath};
            try {
                toast.loading("Submitting...", {id: "driver-alert"});
                const response = await axios.post("/send-driver-alert", data);

                if (response.status === 201) {
                    toast.success("Driver alert sent", {id: "driver-alert"});
                    setTitle("");
                    setDescription("");
                    setPolygonPath([]);
                    handleRemovePolygon()
                } else {
                    toast.error("Error saving data", {id: "driver-alert"});
                }
            } catch (error) {
                toast.error("Error saving data", {id: "driver-alert"});
            }
        }
    };

    return (
        <Box>
            <Typography variant="h1" mb={3}>
                Driver Alerts </Typography>

            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!titleError}
                        helperText={titleError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        margin="normal"
                        error={!!descriptionError}
                        helperText={descriptionError}
                        autoComplete
                    />
                </Grid>
            </Grid>

            {typeof window.google !== "undefined" ? (
                <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)} onPolygonComplete={handlePolygonComplete}/>
            ) : (
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                    <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)}
                                       onPolygonComplete={handlePolygonComplete}/>
                </LoadScript>
            )}
            {polygonError && <Typography color="error" variant="body2" sx={{mt: 1}}>{polygonError}</Typography>}

            <Box sx={{display: "flex", justifyContent: "space-between", mt: 2}}>
                <Button variant="outlined" color="secondary" onClick={handleRemovePolygon}>Remove Polygon</Button>
                <Button variant="contained" color="primary" onClick={handleSubmit}>Send</Button>
            </Box>
        </Box>
    );
};

export default DriverAlerts;
