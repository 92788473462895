import React, { useState } from "react";
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

const SearchBar = ({ onSearch }) => {
    const [searchText, setSearchText] = useState("");

    const handleSearchClick = () => {
        onSearch(searchText);
    };

    const handleClear = () => {
        setSearchText("");
        onSearch(""); // Optionally clear search in the parent as well
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <Box
            display="flex"
            alignItems="end"
            sx={{ width: '100%', maxWidth: 600, mb: 2 , mr:2}} // Center and adjust max width
        >
            <TextField
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search..."
                variant="outlined"
                onKeyDown={handleKeyDown} // Trigger search on Enter key press

                size="medium" // Increase size if desired
                sx={{
                    width: '100%',
                    borderRadius: 1,
                    bgcolor: '#f3f4f6', // Light background for contrast
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "primary.main", // Change border color on focus
                        },
                    },
                    marginRight:2
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {searchText && (
                                <IconButton onClick={handleClear}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                            <IconButton onClick={handleSearchClick}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </Box>
    );
};

export default SearchBar;
