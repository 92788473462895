import React, { useState,useEffect } from "react";
import {Box, Grid, Typography, Chip, TextField, Select, MenuItem, Button, Skeleton} from "@mui/material";
import axios from "axios";
import HelpIcon from "@mui/icons-material/Help";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import FileViewer from "../../shared/FIleViewer";
import { toast } from 'react-hot-toast';  // Import react-hot-toast


const InspectionDetails = ({ initialModalData, handleClose, refetch, driverId }) => {
    const [modalData, setModalData] = useState(initialModalData);
    const [inputValue, setInputValue] = useState(initialModalData?.remarks || "");
    const [selectedStatus, setSelectedStatus] = useState(initialModalData?.status || "");
    const [loading, setLoading] = useState(!initialModalData);

    // Status options
    const statusOptions = [
        { value: "approved", label: "Approved" },
        { value: "resubmission_requested", label: "Resubmission Requested" },
        { value: "declined", label: "Declined" },
        { value: "expired", label: "Expired" },
        { value: "pending", label: "Pending" },
        { value: "not_applied", label: "Not Applied" },
    ];

    // Fetch modal data if not available
    useEffect(() => {
        const fetchModalData = async () => {
            if (!initialModalData) {
                setLoading(true);
                try {
                    const response = await axios.get(`/getInspection/1/${driverId}`);
                    console.log(response.data.data);
                    setModalData(response.data?.data.carInspection);
                    setInputValue(response.data?.data?.carInspection?.remarks || "");
                    setSelectedStatus(response.data?.data?.carInspection?.status || "");
                } catch (error) {
                    console.error("Failed to fetch inspection data:", error);
                    toast.error("Failed to load inspection details.",{
                        icon: "❌",
                        style: { background: "#F44336", color: "white" },
                    });
                } finally {
                    setLoading(false);
                }
            }
        };
        fetchModalData();
    }, [initialModalData, ]);

    const handleUpdate = async () => {
        try {
            const updatedData = {
                remarks: inputValue,
                status: selectedStatus,
            };

            const response = await axios.put(`/updateInspection/${modalData?._id||driverId}`, updatedData);

            toast.success("Inspection updated successfully!", {
                icon: "✅",
                style: { background: "#4CAF50", color: "white" },
            });
            handleClose();
            refetch();
        } catch (error) {
            console.error("Update failed:", error);
            toast.error("Failed to update inspection. Please try again.", {
                icon: "❌",
                style: { background: "#F44336", color: "white" },
            });
        }
    };

    if (loading) {
        // Show skeleton loader while loading
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box padding={2}>
                        <Skeleton variant="text" width="60%" height={30} />
                        <Skeleton variant="text" width="40%" height={20} />
                        <Skeleton variant="rectangular" width="100%" height={200} />
                        <Skeleton variant="text" width="100%" height={30} />
                        <Skeleton variant="text" width="100%" height={30} />
                    </Box>
                </Grid>
            </Grid>
        );
    }
    if (!modalData) {
        return <Typography>No inspection data available.</Typography>;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Box sx={{ padding: 1 }}>
                    <Box display="flex" justifyContent="space-between" mb={2}>
                        <Box flex={1} mr={2}>
                            <Typography variant="h6"><strong>Car Information</strong></Typography>
                            <Typography><strong>Model:</strong> {modalData?.carId?.carModel}</Typography>
                            <Typography><strong>Registration No:</strong> {modalData?.carId?.registrationNumber}</Typography>
                            <Typography><strong>Year:</strong> {modalData?.carId?.year}</Typography>
                            <Typography><strong>Color:</strong> {modalData?.carId?.color}</Typography>
                        </Box>
                        <Box flex={1} ml={2}>
                            <Typography variant="h6"><strong>Driver Information</strong></Typography>
                            <Typography><strong>Name:</strong> {modalData?.driverId?.name}</Typography>
                            <Typography><strong>Email:</strong> {modalData?.driverId?.email}</Typography>
                        </Box>
                    </Box>
                    <Box display="flex" mb={2}>
                        <Grid xs={6}>
                            <Box display="flex" alignItems="center" mb={2}>
                                <Typography variant="body1">
                                    <strong>Status: &nbsp;</strong>
                                </Typography>
                                <Chip
                                    label={getStatusProps(modalData?.status).label || "Unknown"}
                                    icon={getStatusProps(modalData?.status).icon || <HelpIcon />}
                                    style={{
                                        backgroundColor: `${getStatusProps(modalData?.status).color}20`,
                                        color: getStatusProps(modalData?.status).color,
                                    }}
                                    variant="outlined"
                                    size="medium"
                                />
                            </Box>
                        </Grid>
                        <Grid xs={6}>
                            <Box display="flex" alignItems="center" mb={2}>
                                <Typography variant="body1">
                                    <strong>Expiry Date:</strong> {formatDate(modalData?.latestInspection?.expiryDate)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Box>
                    <DocumentViewer modalData={modalData} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextField
                                fullWidth
                                label="Remarks Field"
                                variant="outlined"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Select
                                fullWidth
                                value={selectedStatus}
                                onChange={(e) => setSelectedStatus(e.target.value)}
                                displayEmpty
                                variant="outlined"
                            >
                                {statusOptions.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container justifyContent="center" mt={2}>
                        <Button variant="contained" color="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};
// Helper function for status props
const getStatusProps = (status) => {
    switch (status) {
        case "approved":
            return { label: "Approved", color: "#4caf50", icon: <CheckCircleIcon /> }; // Green
        case "resubmission_requested":
            return { label: "Resubmission Requested", color: "#ff9800", icon: <AutorenewIcon /> }; // Orange
        case "declined":
            return { label: "Declined", color: "#f44336", icon: <CancelIcon /> }; // Red
        case "expired":
            return { label: "Expired", color: "#9e9e9e", icon: <AccessTimeIcon /> }; // Grey
        case "pending":
            return { label: "Pending", color: "#2196f3", icon: <HourglassEmptyIcon /> }; // Blue
        case "not_applied":
            return { label: "Not Applied", color: "#9e9e9e", icon: <NotInterestedIcon /> }; // Grey
        default:
            return { label: "Unknown", color: "#9e9e9e", icon: <HelpIcon /> }; // Default
    }
};

// Helper function to format dates
const formatDate = (date) => {
    return date ? new Date(date).toDateString() : "-";
};

function DocumentSection({ label, fileUrl, fallbackMessage }) {
    return (
        <Box mb={2}>
            <Typography variant="h6" mb={2}>{label}</Typography>
            {fileUrl ? (
                <FileViewer fileUrl={fileUrl} />
            ) : (
                <Typography color="text.secondary">{fallbackMessage}</Typography>
            )}
        </Box>
    );
}

function DocumentViewer({ modalData }) {
    return (
        <>
            <DocumentSection
                label="Inspection Report"
                fileUrl={modalData?.latestInspection?.report}
                fallbackMessage="Report not Available"
            />
        </>
    );
}

export default InspectionDetails;
