import axios from 'axios';

// Set up a base URL for all requests


// GET request helper
export const get = async (url, config = {}) => {
    try {
        const response = await axios.get(url, config);
        return response.data;
    } catch (error) {
        console.error('GET request error:', error);
        throw error;
    }
};

// POST request helper
export const post = async (url, data, config = {}) => {
    try {
        const response = await axios.post(url, data, config);
        return response.data;
    } catch (error) {
        console.error('POST request error:', error);
        throw error;
    }
};

// DELETE request helper
export const del = async (url, config = {}) => {
    try {
        const response = await axios.delete(url, config);
        return response.data;
    } catch (error) {
        console.error('DELETE request error:', error);
        throw error;
    }
};

// PATCH request helper
export const patch = async (url, data, config = {}) => {
    try {
        const response = await axios.patch(url, data, config);
        return response.data;
    } catch (error) {
        console.error('PATCH request error:', error);
        throw error;
    }
};
