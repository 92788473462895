import {useEffect, useState} from 'react';
import axios from 'axios';
import {Box, Card, CardContent, Grid, List, ListItem, ListItemText, Skeleton, Typography} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import toast from 'react-hot-toast'; // Import toast

export const DriverEarnings = ({driverId}) => {
    const [earnings, setEarnings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!driverId) return; // Only fetch if driverId is available

        const fetchEarnings = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`/wallet-info/1/${driverId}`);
                setEarnings(response.data.data); // Set earnings data to state
                console.log(response.data.data); // Log to check the response
            } catch (err) {
                setError("Failed to fetch driver earnings.");
                toast.error("Error fetching driver earnings!"); // Show error toast
            } finally {
                setLoading(false);
            }
        };

        fetchEarnings();
    }, [driverId]);

    // Function to format date and time
    const formatDate = (timestamp) => {
        if (!timestamp) return "Invalid Date";
        const date = new Date(timestamp);
        if (isNaN(date)) return "Invalid Date"; // Check if the date is invalid
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`; // Format date and time
    };

    if (loading) {
        return (
            <Box>
                <Skeleton variant="text" width="50%" height={30}/>
                <Skeleton variant="text" width="30%" height={30}/>
            </Box>
        );
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <Box>

            <Box>
                <Typography variant="h5" gutterBottom>Driver Earnings</Typography>

                <Grid container spacing={2} sx={{mt: 2}}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Total Earnings</Typography>
                                <Typography variant="body1">
                                    ${earnings?.totalEarning?.toFixed(2) ?? "Not Available"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Available Balance</Typography>
                                <Typography variant="body1">
                                    ${earnings?.balance?.toFixed(2) ?? "Not Available"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Total Payouts</Typography>
                                <Typography variant="body1">
                                    ${earnings?.totalPayouts?.toFixed(2) ?? "Not Available"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Eligible Payout</Typography>
                                <Typography variant="body1">
                                    ${(earnings?.eligiblePayout)?.toFixed(2) ?? "Not Available"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h6">Reward Points</Typography>
                                <Typography variant="body1">
                                    {earnings?.reward?.toFixed(2) ?? "Not Available"}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

            <Grid container spacing={3} sx={{mt: 2}}>
                {/* Payout History */}
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">Balance History</Typography>
                    {earnings?.balanceHistory?.length > 0 ? (
                        <Card>
                            <Box sx={{
                                maxHeight: 300,
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {width: 4},
                                '&::-webkit-scrollbar-thumb': {backgroundColor: '#888'},
                                '&::-webkit-scrollbar-thumb:hover': {backgroundColor: '#555'}
                            }}>
                                <List>
                                    {earnings?.balanceHistory?.map((history, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`Amount: $${history.amount.toFixed(2)}`} // Round amount to 2 decimal places
                                                secondary={`Date: ${formatDate(history.timestamp)}`} // Format timestamp
                                            />
                                            {/* Render appropriate icon based on type */}
                                            {history.type === 'debit' ? (
                                                <ArrowDownwardIcon sx={{color: 'red', ml: 2}}/>
                                            ) : (
                                                <ArrowUpwardIcon sx={{color: 'green', ml: 2}}/>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                            </Box>

                        </Card>) : (
                        <Typography variant="body2">No balance history available.</Typography>
                    )}
                </Grid>

                {/* Reward History */}
                {earnings?.rewardHistory?.length > 0 && (
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Reward History</Typography>
                            <Card>
                        <Box sx={{
                            maxHeight: 300,
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {width: 4},
                            '&::-webkit-scrollbar-thumb': {backgroundColor: '#888'},
                            '&::-webkit-scrollbar-thumb:hover': {backgroundColor: '#555'}
                        }}>

                                <List>
                                    {earnings?.rewardHistory?.map((reward, index) => (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`Amount: ${reward.points?.toFixed(2)}`} // Round amount to 2 decimal places
                                                secondary={`Date: ${formatDate(reward.timestamp)}`} // Format timestamp
                                            />
                                            {/* Render appropriate icon based on type */}
                                            {reward.type === 'debit' ? (
                                                <ArrowDownwardIcon sx={{color: 'red', ml: 2}}/>
                                            ) : (
                                                <ArrowUpwardIcon sx={{color: 'green', ml: 2}}/>
                                            )}
                                        </ListItem>
                                    ))}
                                </List>
                        </Box>
                            </Card>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
