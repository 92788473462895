import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import { AccessAlarm, ThumbUp } from '@mui/icons-material';

const ComingSoon = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                backgroundColor: '#f5f5f5',
                textAlign: 'center',
                padding: 3,
            }}
        >
            <AccessAlarm sx={{ fontSize: 100, color: '#ff9800' }} />
            <Typography variant="h3" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                Coming Soon!
            </Typography>
            <Typography variant="h5" sx={{ color: '#757575', marginBottom: 3 }}>
                We're working hard to bring this feature to you. Stay tuned!
            </Typography>




        </Box>
    );
};

export default ComingSoon;
