// CoverageAreaCard.js
import React, { useRef, useEffect } from "react";
import { Card, CardContent, CardActions, Button, Typography } from "@mui/material";
import { GoogleMap, Polygon, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../constants";

const PricingRegionCard = ({ area, onEdit, onDelete }) => {
    const mapContainerStyle = { width: "100%", height: "140px" };
    const mapRef = useRef(null);
    const onLoadMap = (map) => {
        mapRef.current = map;
        fitBounds(map);
    };

    const fitBounds = (map) => {
        if (area.coordinates.length) {
            const bounds = new window.google.maps.LatLngBounds();
            area.coordinates.forEach((coord) => bounds.extend(coord));
            map.fitBounds(bounds);
        }
    };
    const fieldLabels = {
        name: "Name",
        description: "Description",
        baseFare: "Base Fare",
        distanceRate: "Distance Rate",
        timeRate: "Time Rate",
        bookingFeeStandard: "Booking Fee (Standard)",
        bookingFeePremium: "Booking Fee (Premium)",
        bookingFeeSurge: "Booking Fee (Surge)",
    };
    return (
        <Card sx={{ width: 345 }}>
            {/* Google Map Displaying the Polygon */}
            {typeof window.google !== 'undefined' ?  <GoogleMap
                mapContainerStyle={mapContainerStyle}
                onLoad={onLoadMap}
                zoom={8} //
                options={{disableDefaultUI: true,keyboardShortcuts:false, }}
                // Initial zoom; will be adjusted by fitBounds
            >
                <Polygon
                    path={area.coordinates}
                    options={{
                        fillColor: "#2196f3",
                        fillOpacity: 0.3,
                        strokeColor: "#2196f3",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                    }}
                />
            </GoogleMap>:(<LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoadMap}
                    zoom={8} //
                    // Initial zoom; will be adjusted by fitBounds
                >
                    <Polygon
                        path={area.coordinates}
                        options={{
                            fillColor: "#2196f3",
                            fillOpacity: 0.3,
                            strokeColor: "#2196f3",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }}
                    />
                </GoogleMap>
            </LoadScript>)}

            <CardContent>
                {Object.entries(area)
                    .filter(([key]) => !["coordinates", "_id", "createdAt", "updatedAt", "__v"].includes(key))
                    .map(([key, value]) => (
                        <Typography key={key} fontWeight="500" sx={{ marginBottom: 1 }}>
                            {fieldLabels[key]}: {value || "N/A"}
                        </Typography>
                    ))}
            </CardContent>

            <CardActions>
                {/*<Button size="small" onClick={() => onEdit(area)}>Edit</Button>*/}
                <Button size="small" variant='outlined' color="secondary" onClick={() => onDelete(area._id)}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    );
};

export default PricingRegionCard;
