import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { ASSET_IMAGES } from "../utils/constants/paths";
import { getAssetPath } from "../utils/appHelpers";
import * as yup from "yup";
import { Form, Formik } from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useAuth } from "app/context/AuthContext";
import {Link} from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});
function delay(ms) {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}
const Login = ({ disableSmLogin }) => {
  const navigate = useNavigate();
  const auth = useAuth();

  const onSignIn = async (email, password) => {
    try {
      toast.loading("Signing In...", { id: "auth" });
     let data= await auth.login(email, password);
      console.log('Login Role',data.role)
       await delay(1000)
           if (data.role ==='admin'){
                navigate("/admin");
            }
            if (data.role ==='CorporateAdmin'){
                navigate("/corporate");
            }
        if (data.role ==='subAdmin'){
            navigate("/subAdmin");
        }

            toast.dismiss("auth");
    } catch (e) {
      toast.error("Unable to Sign In", { id: "auth" });
    }
  };

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            background: `#0267a0 url(${getAssetPath(
              `${ASSET_IMAGES}/widgets/keith-luke.jpg`,
              "640x428"
            )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0267a0", 0.65),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mb: 2 }}>
              <Typography
                variant={"h3"}
                color={"inherit"}
                fontWeight={500}
                mb={3}
              >
                Sign In
              </Typography>
              <Typography variant={"body1"} mb={2}>
                Welcome to ICE Ride Hailing Admin Dashboard
              </Typography>

            </Div>

            {/* <Div sx={{ mt: "auto" }}>*/}
            {/*  <Link href="#" underline="none" sx={{ display: "inline-flex" }}>*/}
            {/*    <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Jumbo React" />*/}
            {/*  </Link>*/}
            {/*</Div>*/}
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <Formik
            validateOnChange={true}
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
              setSubmitting(true);
            await  onSignIn(data.email, data.password);
              setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => (
              <Form style={{ textAlign: "left" }} noValidate autoComplete="off">
                <Div sx={{ mt: 1, mb: 3 }}>
                  <JumboTextField fullWidth name="email" label="Email" autoComplete={'email'} />
                </Div>
                <Div sx={{ mt: 1, mb: 2 }}>
                  <JumboTextField
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete={'password'}
                  />
                </Div>
                {/* <Div sx={{ mb: 2 }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Remember me"
                  />
                </Div> */}
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{ mb: 3 }}
                  loading={isSubmitting}
                >
                  Sign In
                </LoadingButton>
              </Form>
            )}
          </Formik>
            <Link to={"business-signup"} color={"primary"}  style={{ textDecoration: 'none', color: 'inherit' }}>
                <Typography variant={"body1"}>
                    Business SignUp
                </Typography>
            </Link>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login;
