// UpdateAdminPass.js

import React, { useState } from "react";
import { TextField, Button, Typography, Alert, Stack, Card, CardContent, CardActions } from "@mui/material";
import axios from "axios";

const UpdateAdminPass = () => {
    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const handleUpdatePass = async () => {
        setMessage("");
        setError("");

        // Validation
        if (newPass.length < 8) {
            setError("Password must be at least 8 characters long.");
            return;
        }
        if (newPass !== confirmPass) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await axios.put("/update-admin-pass", { newPass });
            setMessage(response.data.message);
            setNewPass("");
            setConfirmPass("");
        } catch (err) {
            setError(err.response?.data?.error || "Failed to update password");
        }
    };

    return (
        <Stack alignItems="center" mt={5}>
            <Card sx={{ maxWidth: 400, width: "100%", boxShadow: 3, padding: 2 }}>
                <CardContent>
                    <Typography variant="h5" gutterBottom align="center" mb={2}>
                        Update Admin Password
                    </Typography>

                    {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}
                    {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

                    <TextField
                        label="New Password"
                        type="password"
                        value={newPass}
                        onChange={(e) => setNewPass(e.target.value)}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: 2 }}
                    />

                    <TextField
                        label="Confirm Password"
                        type="password"
                        value={confirmPass}
                        onChange={(e) => setConfirmPass(e.target.value)}
                        variant="outlined"
                        fullWidth
                        required
                        sx={{ mb: -1}}
                    />
                </CardContent>

                <CardActions sx={{ justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={handleUpdatePass} sx={{ width: "100%" }}>
                        Update Password
                    </Button>
                </CardActions>
            </Card>
        </Stack>
    );
};

export default UpdateAdminPass;
