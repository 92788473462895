import React, {useEffect, useState} from "react";
import {Button, Grid, Typography} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {ContentState, convertFromHTML, convertToRaw, EditorState,} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

let Editor = () => <React.Fragment/>;

const HowItWorks = () => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const getData = () => {
        // toast.loading("fetching data", { id: "data" });

        axios
            .get("/get-how-it-works/v1")
            .then(({data}) => {
                const contentDataState = ContentState.createFromBlockArray(
                    convertFromHTML(data.data['how-it-works'])
                );
                const editorDataState = EditorState.createWithContent(contentDataState);

                console.log("Data", editorDataState);
                setEditorState(editorDataState);
                toast.success("Data Updated", {id: "data",  icon: '✅',  // Optional icon
                    style: {
                        background: '#4CAF50',  // Green background for success
                        color: 'white',          // White text color
                    }});
            })
            .catch((e) => {
                console.error(e);
                toast.error("Error Fetching Data 11", {id: "data"});
            });
    };
    useEffect(() => {
        getData();
    }, []);

    const handleSubmit = (event) => {
        toast.loading("Updating Data", {id: "saA"});
        axios
            .post("/how-it-works/v1", {
                html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
            })
            .then(({data}) => {
                toast.dismiss("saA");
                getData();
            })
            .catch(() => {
                toast.error("Unable to update", {id: "saA"});
            });
    };

    const {t} = useTranslation();

    useEffect(() => {
        Editor = require("react-draft-wysiwyg").Editor;
        setEditorState(EditorState.createEmpty());
    }, []);
    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.howItWorks")}
            </Typography>
            <Grid>
                <Editor
                    editorStyle={{
                        width: "100%",
                        minHeight: 100,
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "lightgray",
                        backgroundColor: "white",
                        padding: "2rem",
                    }}
                    editorState={editorState}
                    onEditorStateChange={(editorState) => setEditorState(editorState)}
                />
                {/* <textarea
          style={{ width: "100%", height: 200 }}
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
                <Button
                    variant="contained"
                    onClick={handleSubmit}
                    style={{marginTop: 15}}
                >
                    Submit
                </Button>
            </Grid>
        </>
    );
};

export default HowItWorks;
