import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import JumboApp from "@jumbo/components/JumboApp";
import JumboTheme from "@jumbo/components/JumboTheme";
import JumboDialog from "@jumbo/components/JumboDialog";
import JumboDialogProvider from "@jumbo/components/JumboDialog/JumboDialogProvider";
import { SnackbarProvider } from "notistack";
import AppProvider from "./AppProvider";
import { config } from "./config/main";
import JumboRTL from "@jumbo/JumboRTL/JumboRTL";
import Div from "@jumbo/shared/Div";
import {CircularProgress, Skeleton} from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import { AuthProvider } from "./context/AuthContext";
import AppRoutes from "./AppRoutes";
import {GOOGLE_MAPS_API_KEY} from "../constants";
import {LoadScript, useJsApiLoader} from "@react-google-maps/api";
import { createTheme, ThemeProvider, GlobalStyles, CssBaseline } from '@mui/material';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});


function App() {
  return (
    <>
      <AuthProvider>
        <Toaster position="bottom-right" />
        <QueryClientProvider client={queryClient}>
          {/* <Provider store={store}> */}
          <BrowserRouter>
              {/*<LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>*/}

              <AppProvider>
              <JumboApp activeLayout={config.defaultLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <JumboDialogProvider>
                        <CssBaseline />
                        <GlobalStyles
                            styles={{
                                '*::-webkit-scrollbar': { width: 8 },
                                '*::-webkit-scrollbar-thumb': {
                                    backgroundColor: '#888',
                                    '&:hover': { backgroundColor: '#555' },
                                },
                            }}
                        />
                      <JumboDialog />
                      <SnackbarProvider
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        maxSnack={3}
                      >
                        {/* <AppLayout> */}
                        <Suspense
                          fallback={
                              <Div
                                  sx={{
                                      display: "flex",
                                      minWidth: 0,
                                      alignItems: "center",
                                      alignContent: "center",
                                      height: "100%",
                                  }}
                              >
                                  {/*<div style={{padding: "20px"}}>*/}
                                  {/*    <Skeleton variant="rectangular" height={40} width="100%"/>*/}
                                  {/*    <Skeleton variant="rectangular" height={40} width="100%" style={{marginTop: 10}}/>*/}
                                  {/*    <Skeleton variant="rectangular" height={40} width="100%" style={{marginTop: 10}}/>*/}
                                  {/*</div>*/}
                                  <CircularProgress sx={{m: "-40px auto 0"}}/>
                              </Div>
                          }
                        >
                            <AppRoutes/>
                        </Suspense>
                          {/* </AppLayout> */}
                      </SnackbarProvider>
                    </JumboDialogProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
              </AppProvider>
              {/*</LoadScript>*/}
          </BrowserRouter>
          {/* </Provider> */}
        </QueryClientProvider>
      </AuthProvider>
    </>
  );
}

export default App;
