import React, {useEffect, useState} from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import HelpIcon from '@mui/icons-material/Help';

import { Typography } from "@mui/material";

import Datagrid from "app/components/Datagrid";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";

const Rentals = () => {
  const [refetch, setRefetch] = useState(1);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.car.name}</Typography>
      ),
    },
    {
      field: "Regno",
      headerName: "Reg No.",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.car.RegNo}</Typography>
      ),
    },
    {
      field: "rentPerDay",
      headerName: "Rent per day",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.rentPerDay}</Typography>
      ),
    },
    {
      field: "availabilityStatus",
      headerName: "Availability Status",
      width: 200,
      renderCell: (params) => {
        const availabilityStatus = params.row?.status;

        const getAvailabilityStatusProps = () => {
          switch (availabilityStatus) {
            case 'Available':
              return { label: "Available", icon: <CheckCircleIcon style={{ color: "#4caf50" }} /> };
            case 'Not Listed':
              return { label: "Not Listed", icon: <VisibilityOffIcon style={{ color: "#9e9e9e" }} /> };
            case 'Payment Pending':
              return { label: "Payment Pending", icon: <AccessTimeIcon style={{ color: "#ff9800" }} /> };
            case 'Booked':
              return { label: "Booked", icon: <EventBusyIcon style={{ color: "#f44336" }} /> };
            default:
              return { label: `Unknown (${availabilityStatus})`, icon: <HelpIcon style={{ color: "#9e9e9e" }} /> };
          }
        };

        const { label, icon } = getAvailabilityStatusProps();

        return (
            <Chip
                label={label}
                icon={icon}
                variant="outlined"
                size="medium"
            />
        );
      }
    },
   
    {
      headerName: "Available From",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.startTime).toLocaleString()}
        </Typography>
      ),
    },
    {
      headerName: "Available Till",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.endTime).toLocaleString()}
        </Typography>
      ),
    },

  ];

  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h1" mb={3}>
        {t("pages.title.rentals")}
      </Typography>

      {/* Modal  */}

      <Datagrid url={'/rentals'} columns={columns} refetch={refetch}/>
    </>
  );
};

export default Rentals;
