import React, { Suspense } from "react";
import { IconButton } from "@mui/material";
import JumboVerticalNavbar from "@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import useJumboSidebarTheme from "@jumbo/hooks/useJumboSidebarTheme";
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from "@jumbo/utils/constants/layout";
import Logo from "../../../../shared/Logo";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Zoom from "@mui/material/Zoom";
import Div from "@jumbo/shared/Div";
import SidebarSkeleton from "./SidebarSkeleton";
import {useAuth} from "../../../../context/AuthContext";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
import PaidIcon from "@mui/icons-material/Paid";
import CarRentalIcon from "@mui/icons-material/CarRental";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DirectionsCarFilledIcon from "@mui/icons-material/DirectionsCarFilled";
import PolicyIcon from "@mui/icons-material/Policy";
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocationDisabledIcon from '@mui/icons-material/LocationDisabled';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import DiscountIcon from '@mui/icons-material/Discount';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import DnsIcon from '@mui/icons-material/Dns';
const Sidebar = () => {
    const auth = useAuth()

    let menus= [];
    if (auth.role==='admin'){
        menus = [
            {
                label: "Dashboard",
                type: "section",
                children: [
                    {
                        uri: "/admin",
                        label: "sidebar.menuItem.Dashboard",
                        type: "nav-item",
                        icon: <DashboardIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },   {
                label: "Marketing",
                type: "section",
                children: [
                    {
                        uri: "/admin/driver-alerts",
                        label: "sidebar.menuItem.DriverAlerts",
                        type: "nav-item",
                        icon: <TaxiAlertIcon sx={{ fontSize: 20 }} />,
                    },  {
                        uri: "/admin/promotions",
                        label: "sidebar.menuItem.Promotions",
                        type: "nav-item",
                        icon: <DiscountIcon  sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Drivers",
                type: "section",
                children: [
                    {
                        uri: "/admin/drivers",
                        label: "sidebar.menuItem.Drivers",
                        type: "nav-item",
                        icon: <DirectionsCarFilledIcon sx={{ fontSize: 20 }} />,
                    },   {
                        uri: "/admin/inspections",
                        label: "sidebar.menuItem.Inspections",
                        type: "nav-item",
                        icon: <DirectionsCarFilledIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Sub Admins",
                type: "section",
                children: [
                    {
                        uri: "/admin/subAdmins",
                        label: "sidebar.menuItem.SubAdmin",
                        type: "nav-item",
                        icon: <SupervisorAccountIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Complaints",
                type: "section",
                children: [
                    {
                        uri: "/admin/complaints",
                        label: "sidebar.menuItem.Complaints",
                        type: "nav-item",
                        icon: <QuestionAnswerIcon  sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Riders",
                type: "section",
                children: [
                    {
                        uri: "/admin/riders",
                        label: "sidebar.menuItem.Riders",
                        type: "nav-item",
                        icon: <PersonIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Corporate",
                type: "section",
                children: [
                    {
                        uri: "/admin/corporates",
                        label: "sidebar.menuItem.Corporates",
                        type: "nav-item",
                        icon: <CorporateFareIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Rentals",
                type: "section",
                children: [
                    {
                        uri: "/admin/rentals",
                        label: "sidebar.menuItem.Rentals",
                        type: "nav-item",
                        icon: <CarRentalIcon sx={{ fontSize: 20 }} />,
                    },
                    // {
                    //   uri: "/dashboard",
                    //   label: "sidebar.menuItem.Rentals",
                    //   type: "nav-item",
                    //   icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
                    // },
                    // {
                    //   uri: "/dashboard",
                    //   label: "sidebar.menuItem.Rentals",
                    //   type: "nav-item",
                    //   icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
                    // },
                ],
            },
            {
                label: "Payments",
                type: "section",
                children: [
                    {
                        uri: "/admin/payments",
                        label: "sidebar.menuItem.Payments",
                        type: "nav-item",
                        icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: "/admin/pricing",
                        label: "sidebar.menuItem.pricing",
                        type: "nav-item",
                        icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: "/admin/surge-zones",
                        label: "sidebar.menuItem.SurgeZones",
                        type: "nav-item",
                        icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    },   {
                        uri: "/admin/pricing-regions",
                        label: "sidebar.menuItem.pricingRegions",
                        type: "nav-item",
                        icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },  {
                label: "Coverage",
                type: "section",
                children: [
                    {
                        uri: "/admin/operational-zones",
                        label: "sidebar.menuItem.OperationalZones",
                        type: "nav-item",
                        icon: <AddLocationIcon sx={{ fontSize: 20 }} />,
                    },
                    // {
                    //     uri: "/admin/pricing",
                    //     label: "sidebar.menuItem.pricing",
                    //     type: "nav-item",
                    //     icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    // },
                ],
            },
            {
                label: "App Management",
                type: "section",
                children: [
                    {
                        uri: "/admin/intro-screens",
                        label: "sidebar.menuItem.introScreens",
                        type: "nav-item",
                        icon: <MobileScreenShareIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: "/admin/legal",
                        label: "sidebar.menuItem.legal",
                        type: "nav-item",
                        icon: <PolicyIcon sx={{ fontSize: 20 }} />,
                    },{
                        uri: "/admin/howItWorks",
                        label: "sidebar.menuItem.howItWorks",
                        type: "nav-item",
                        icon: <PolicyIcon sx={{ fontSize: 20 }} />,
                    },{
                        uri: "/admin/server-monitoring",
                        label: "sidebar.menuItem.serverMonitoring",
                        type: "nav-item",
                        icon: <DnsIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
        ];

    }
    if (auth.role==='subAdmin'){
        menus = [
            {
                label: "Dashboard",
                type: "section",
                children: [
                    {
                        uri: "/admin",
                        label: "sidebar.menuItem.Dashboard",
                        type: "nav-item",
                        icon: <DashboardIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Drivers",
                type: "section",
                children: [
                    {
                        uri: "/admin/drivers",
                        label: "sidebar.menuItem.Drivers",
                        type: "nav-item",
                        icon: <DirectionsCarFilledIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },

            {
                label: "Riders",
                type: "section",
                children: [
                    {
                        uri: "/admin/riders",
                        label: "sidebar.menuItem.Riders",
                        type: "nav-item",
                        icon: <PersonIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Corporate",
                type: "section",
                children: [
                    {
                        uri: "/admin/corporates",
                        label: "sidebar.menuItem.Corporates",
                        type: "nav-item",
                        icon: <CorporateFareIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Rentals",
                type: "section",
                children: [
                    {
                        uri: "/admin/rentals",
                        label: "sidebar.menuItem.Rentals",
                        type: "nav-item",
                        icon: <CarRentalIcon sx={{ fontSize: 20 }} />,
                    },
                    // {
                    //   uri: "/dashboard",
                    //   label: "sidebar.menuItem.Rentals",
                    //   type: "nav-item",
                    //   icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
                    // },
                    // {
                    //   uri: "/dashboard",
                    //   label: "sidebar.menuItem.Rentals",
                    //   type: "nav-item",
                    //   icon: <GraphicEqIcon sx={{ fontSize: 20 }} />,
                    // },
                ],
            },
            {
                label: "Payments",
                type: "section",
                children: [
                    {
                        uri: "/admin/payments",
                        label: "sidebar.menuItem.Payments",
                        type: "nav-item",
                        icon: <PaidIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "App Managment",
                type: "section",
                children: [
                    {
                        uri: "/admin/intro-screens",
                        label: "sidebar.menuItem.introScreens",
                        type: "nav-item",
                        icon: <MobileScreenShareIcon sx={{ fontSize: 20 }} />,
                    },
                    {
                        uri: "/admin/legal",
                        label: "sidebar.menuItem.legal",
                        type: "nav-item",
                        icon: <PolicyIcon sx={{ fontSize: 20 }} />,
                    },{
                        uri: "/admin/howItWorks",
                        label: "sidebar.menuItem.howItWorks",
                        type: "nav-item",
                        icon: <PolicyIcon sx={{ fontSize: 20 }} />,
                    }
                ],
            },
        ];

    }
    if (auth.role==='complaints'){
        menus = [
            {
                label: "Complaints",
                type: "section",
                children: [
                    {
                        uri: "/admin/complaints",
                        label: "sidebar.menuItem.Complaints",
                        type: "nav-item",
                        icon: <QuestionAnswerIcon  sx={{ fontSize: 20 }} />,
                    },
                ],
            },

        ];

    }

    if (auth.role==='CorporateAdmin'){
        menus = [


            {
                label: "Dashboard",
                type: "section",
                children: [
                    {
                        uri: "/admin",
                        label: "sidebar.menuItem.Dashboard",
                        type: "nav-item",
                        icon: <DashboardIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },
            {
                label: "Users",
                type: "section",
                children: [
                    {
                        uri: "/corporate/users",
                        label: "sidebar.menuItem.users",
                        type: "nav-item",
                        icon: <DirectionsCarFilledIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },


            {
                label: "Corporate",
                type: "section",
                children: [
                    {
                        uri: "/admin/corporates",
                        label: "sidebar.menuItem.Corporates",
                        type: "nav-item",
                        icon: <CorporateFareIcon sx={{ fontSize: 20 }} />,
                    },
                ],
            },

        ];

    }

    return (
    <React.Fragment>
      <SidebarHeader />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: "flex",
                minWidth: 0,
                alignItems: "center",
                alignContent: "center",
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menus} />
        </Suspense>
      </JumboScrollbar>
    </React.Fragment>
  );
};

const SidebarHeader = () => {
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);

  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo mini={isMiniAndClosed} mode={sidebarTheme.type} />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
