import axios from "axios";

export const loginUser = async (email, password) => {
  const res = await axios.post("/login", { email, password });
  if (res.status !== 200) {
    throw new Error("Unable to login please check credentials");
  }
  const data = await res.data;
  console.log(data);
  sessionStorage.setItem("authUser", JSON.stringify(data));
  return data;
};
export const signupUser = async (name, email, password) => {
  const res = await axios.post("/user/signup", { name, email, password });
  if (res.status !== 201) {
    throw new Error("Unable to Signup");
  }
  const data = await res.data;
  return data;
};
export const checkAuthStatus = async () => {
  const res = await axios.get("/auth-status");
  if (!(res.status === 200 || res.status === 304)) {
    throw new Error("Unable to authenticate");
  }
  const data = await res.data;
  return data;
};

export const logoutUser = async () => {
  const res = await axios.get("/logout");
  if (res.status !== 200) {
    throw new Error("Unable to logout");
  }
  const data = await res.data;
  return data;
};
