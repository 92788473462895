import React, {useState} from "react";
import {Box, Divider, IconButton, Modal, Tab, Tabs, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InspectionDetails from "./InspectionDetails";
import {InspectionHistory} from "./InspectionHistory";


const InspectionModel = ({handleClose, modalData, open, refetch}) => {
    const [activeTab, setActiveTab] = useState(0);
    console.log(modalData)
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    console.log('modalData', modalData)

    return (
        <Modal open={open} onClose={handleClose} sx={{}}>
            <Box sx={{
                padding: 4,
                backgroundColor: "white",
                width: '50%',
                height: '80%',
                margin: "auto",
                mt: 4,
                borderRadius: 2,
                overflow: 'auto'
            }}>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>Inspection Details</Typography>
                    <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </Box>

                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Details"/>
                    <Tab label="Inspection History"/>
                </Tabs>


                <Divider sx={{marginBottom: 4}}
                />


                {activeTab === 0 && (
                    <InspectionDetails initialModalData={modalData} handleClose={handleClose} refetch={refetch}/>
                )}
                {activeTab === 1 && (
                    <InspectionHistory modalData={modalData}/>
                )}


            </Box>
        </Modal>
    );
};


export default InspectionModel;
