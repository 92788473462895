import React, {useEffect, useRef, useState} from "react";
import {Box, Button, Grid, IconButton, Modal, Skeleton, TextField, Typography} from "@mui/material";
import {DrawingManager, GoogleMap, LoadScript} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import toast from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import PricingRegionCard from "../components/PricingRegionCard";

// Memoize GoogleMap to avoid unnecessary re-renders
const MemoizedGoogleMap = React.memo(({onLoad, onPolygonComplete}) => {
    return (<GoogleMap
        mapContainerStyle={{width: "100%", height: "400px"}}
        center={{lat: 37.0902, lng: -95.7129}} // Initial fixed center
        zoom={4}
        onLoad={onLoad}
    >
        <DrawingManager
            onPolygonComplete={onPolygonComplete}
            options={{
                drawingControl: true, drawingControlOptions: {
                    drawingModes: ["polygon"],
                }, polygonOptions: {
                    editable: true,
                },
            }}
        />
    </GoogleMap>);
});

export const PricingRegions = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState(""); // State for description
    const [polygonPath, setPolygonPath] = useState([]);
    const [nameError, setNameError] = useState(""); // Error state for name
    const [descriptionError, setDescriptionError] = useState(""); // Error state for description
    const [polygonError, setPolygonError] = useState(""); // Error state for polygon
    const mapRef = useRef();
    const polygonRef = useRef(null);
    const [baseFare, setBaseFare] = useState("");
    const [distanceRate, setDistanceRate] = useState("");
    const [timeRate, setTimeRate] = useState("");
    const [bookingFeeStandard, setBookingFeeStandard] = useState("");
    const [bookingFeePremium, setBookingFeePremium] = useState("");
    const [bookingFeeSurge, setBookingFeeSurge] = useState("");
    const [baseFareError, setBaseFareError] = useState("");
    const [distanceRateError, setDistanceRateError] = useState("");
    const [timeRateError, setTimeRateError] = useState("");
    const [bookingFeeStandardError, setBookingFeeStandardError] = useState("");
    const [bookingFeePremiumError, setBookingFeePremiumError] = useState("");
    const [bookingFeeSurgeError, setBookingFeeSurgeError] = useState("");

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setName("");
        setDescription(""); // Clear description on close
        setPolygonPath([]);
        setNameError(""); // Clear name error on close
        setDescriptionError(""); // Clear description error on close
        setPolygonError(""); // Clear polygon error on close
        if (polygonRef.current) {
            polygonRef.current.setMap(null); // Remove polygon from the map
            polygonRef.current = null;
        }
        setBaseFare("");
        setDistanceRate("");
        setTimeRate("");
        setBookingFeeStandard("");
        setBookingFeePremium("");
        setBookingFeeSurge("");
        setBaseFareError("");
        setDistanceRateError("");
        setTimeRateError("");
        setBookingFeeStandardError("");
        setBookingFeePremiumError("");
        setBookingFeeSurgeError("");

        setOpen(false);
    };

    const handlePolygonComplete = (polygon) => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
        }

        const path = polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(), lng: latLng.lng(),
        }));
        setPolygonPath(path);
        polygonRef.current = polygon; // Store reference to polygon

        setPolygonError("");

        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({lat, lng}) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
        mapRef.current.fitBounds(bounds);
    };

    const handleRemovePolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
            setPolygonPath([]);
            setPolygonError("");
        }
    };

    const handleSubmit = async () => {
        let valid = true;

        if (!name.trim()) {
            setNameError("Region Name is required");
            valid = false;
        } else {
            setNameError("");
        }

        if (!description.trim()) {
            setDescriptionError("Description is required");
            valid = false;
        } else {
            setDescriptionError("");
        }

        if (polygonPath.length === 0) {
            setPolygonError("Polygon is required");
            valid = false;
        } else {
            setPolygonError("");
        }
        if (!baseFare || isNaN(baseFare)) {
            setBaseFareError("Base Fare must be a valid number");
            valid = false;
        }
        if (!distanceRate || isNaN(distanceRate)) {
            setDistanceRateError("Distance Rate must be a valid number");
            valid = false;
        }
        if (!timeRate || isNaN(timeRate)) {
            setTimeRateError("Time Rate must be a valid number");
            valid = false;
        }
        if (!bookingFeeStandard || isNaN(bookingFeeStandard)) {
            setBookingFeeStandardError("Standard Booking Fee must be a valid number");
            valid = false;
        }
        if (!bookingFeePremium || isNaN(bookingFeePremium)) {
            setBookingFeePremiumError("Premium Booking Fee must be a valid number");
            valid = false;
        }
        if (!bookingFeeSurge || isNaN(bookingFeeSurge)) {
            setBookingFeeSurgeError("Surge Booking Fee must be a valid number");
            valid = false;
        }

        if (valid) {
            const data = {
                name,
                description,
                baseFare: Number(baseFare),
                distanceRate: Number(distanceRate),
                timeRate: Number(timeRate),
                bookingFeeStandard: Number(bookingFeeStandard),
                bookingFeePremium: Number(bookingFeePremium),
                bookingFeeSurge: Number(bookingFeeSurge),
                coordinates: polygonPath,
            };
            try {
                toast.loading("Submitting...", {id: "coverage"});
                const response = await axios.post("/save-pricing-region", data);

                if (response.status === 201) {
                    toast.success("Coverage area created", {
                        id: "coverage", icon: '✅',  // Optional icon
                        style: {
                            background: '#4CAF50',  // Green background for success
                            color: 'white',          // White text color
                        }
                    });
                    fetchPricingRegions();
                    handleClose(); // todo uncomment
                } else {
                    toast.error("Error saving data", {id: "coverage"});
                }
            } catch (error) {
                toast.error("Error saving data", {id: "coverage"});
            }
        }
    };

    const modal = (<Modal open={open} onClose={handleClose}>
        <Box sx={{padding: 4, backgroundColor: "white", width: '90%', margin: "auto", mt: 4, borderRadius: 2}}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>
                    Add Pricing
                </Typography>
                <IconButton onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </Box>
            <Grid container spacing={1} sx={{mt: 2}}>
                <Grid item xs={6}>

                    <TextField
                        label="Area Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        fullWidth

                        margin="normal"
                        error={!!nameError}
                        helperText={nameError}
                    />
                </Grid>
                <Grid item xs={6}>

                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth

                        margin="normal"
                        error={!!descriptionError}
                        helperText={descriptionError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Base Fare"
                        value={baseFare}
                        onChange={(e) => setBaseFare(e.target.value)}
                        fullWidth type='Number'

                        margin="normal"
                        error={!!baseFareError}
                        helperText={baseFareError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Distance Rate"
                        value={distanceRate}
                        onChange={(e) => setDistanceRate(e.target.value)}
                        fullWidth type='Number'

                        margin="normal"
                        error={!!distanceRateError}
                        helperText={distanceRateError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Time Rate"
                        value={timeRate}
                        onChange={(e) => setTimeRate(e.target.value)}
                        fullWidth type='Number'

                        margin="normal"
                        error={!!timeRateError}
                        helperText={timeRateError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Booking Fee (Standard)"
                        value={bookingFeeStandard}
                        onChange={(e) => setBookingFeeStandard(e.target.value)}
                        fullWidth
                        type='Number'
                        margin="normal"
                        error={!!bookingFeeStandardError}
                        helperText={bookingFeeStandardError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Booking Fee (Premium)"
                        value={bookingFeePremium}
                        onChange={(e) => setBookingFeePremium(e.target.value)}
                        fullWidth type='Number'

                        margin="normal"
                        error={!!bookingFeePremiumError}
                        helperText={bookingFeePremiumError}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Booking Fee (Surge)"
                        value={bookingFeeSurge}
                        onChange={(e) => setBookingFeeSurge(e.target.value)}
                        fullWidth
                        type='Number'
                        margin="normal"
                        error={!!bookingFeeSurgeError}
                        helperText={bookingFeeSurgeError}
                    />
                </Grid>
            </Grid>
            {polygonPath.length > 0 && (<IconButton color="secondary" onClick={handleRemovePolygon}>
                <DeleteIcon/>
            </IconButton>)}
            {typeof window.google !== 'undefined' ? (<MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)}
                                                                        onPolygonComplete={handlePolygonComplete}/>) : (
                <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing", "places"]}>
                    <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)}
                                       onPolygonComplete={handlePolygonComplete}/>
                </LoadScript>)}
            {polygonError && (<Typography color="error" variant="body2" sx={{mt: 1}}>
                {polygonError}
            </Typography>)}
            <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{mt: 2}}>
                Submit
            </Button>
        </Box>
    </Modal>);

    // Fetch and display areas (remains the same)
    const [coverageAreas, setCoverageAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchPricingRegions = async () => {
        try {
            const response = await axios.get("/pricing-regions");
            setCoverageAreas(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error("Failed to fetch pricing regions.");
        }
    };
    useEffect(() => {
        fetchPricingRegions();
    }, []);
    const handleEdit = (area) => {
        console.log("Editing area:", area);
    };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/pricing-regions/${id}`);
            setCoverageAreas(coverageAreas.filter((area) => area._id !== id));
            toast.success("Pricing region deleted.", {
                icon: '✅',  // Optional icon
                style: {
                    background: '#4CAF50',  // Green background for success
                    color: 'white',          // White text color
                }
            });
        } catch (err) {
            toast.error("Failed to delete pricing regions.");
        }
    };

    const areas = loading ? (<Box display="flex" flexDirection="column" gap={2}>
        <Skeleton variant="text" width="60%" height={40}/>
        <Skeleton variant="rectangular" width="100%" height={200}/>
    </Box>) : (<Box>
        <Typography variant="h4" gutterBottom>Pricing Regions</Typography>
        {coverageAreas.length === 0 ? <Typography>Pricing Regions</Typography> : (
            <Box display="flex" flexWrap="wrap" gap={2}>
                {coverageAreas.map((area) => (<PricingRegionCard key={area._id} area={area} onEdit={handleEdit}
                                                                onDelete={() => handleDelete(area._id)}/>))}
            </Box>)}
    </Box>);

    return (<>
        <Box display="flex" justifyContent="flex-end" mb={2}>
            <Button variant="contained" color="primary" onClick={handleOpen}>Add Pricing Region</Button>
        </Box>
        {modal}
        {areas}
    </>);
};
