import React, {useState} from 'react';
import {
    Box,
    Button,
    FormLabel,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography
} from '@mui/material';
import {Info as InfoIcon} from '@mui/icons-material';
import {Form, Formik, useField} from 'formik';
import * as Yup from 'yup';
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import Div from "@jumbo/shared/Div";
import FormControlLabel from "@mui/material/FormControlLabel";
import {post} from "../utils/httpRequestsHelper";
import toast from "react-hot-toast";
import {useNavigate} from "react-router-dom";

const steps = [
    'Company Details',
    'Email Domain(s)',
    'Billing Information',
    'Delegate Management',
    'Billing',
    'Review' // Add a new step for review
];

const validationSchemaStep1 = Yup.object({
    companyName: Yup.string().required('Required'),
    contactPerson: Yup.string().required('Required'),
    contactEmail: Yup.string().email('Invalid email format').required('Required'),
    phone: Yup.string().required('Required'),
    completeAddress: Yup.string().required('Required'),
});

const validationSchemaStep2 = Yup.object({
    emailDomains: Yup.string().required('Required'),
});

const validationSchemaStep3 = Yup.object({
    billingContactPerson: Yup.string().required('Required'),
    billingEmail: Yup.string().email('Invalid email format').required('Required'),
    billingAddress: Yup.string().required('Required'),
    paymentMethod: Yup.string().required('Required'),
});

const validationSchemaStep4 = Yup.object({
    delegateManagementOption: Yup.string().required('Required'),
});

const validationSchemaStep5 = Yup.object({
    billingOption: Yup.string().required('Required'),
});

// Validation schema for the review step
const validationSchemaReview = Yup.object().shape({
    // Include all fields for validation in the review step if needed
});

const initialValues = {
    companyName: '',
    contactPerson: '',
    contactEmail: '',
    phone: '',
    completeAddress: '',
    emailDomains: '',
    billingContactPerson: '',
    billingEmail: '',
    billingAddress: '',
    paymentMethod: '',
    delegateManagementOption: '',
    billingOption: '',
};

const renderTooltipIcon = (tooltipText) => (
    <InputAdornment position="end">
        <Tooltip title={tooltipText}>
            <IconButton edge="end">
                <InfoIcon/>
            </IconButton>
        </Tooltip>
    </InputAdornment>
);

const PaymentMethodFormSection = () => {
    const [field, meta, helpers] = useField('paymentMethod');

    return (
        <div>
            <FormLabel id="payment-method-label">Payment Method</FormLabel>
            <RadioGroup
                row
                aria-labelledby="payment-method-label"
                {...field}
                onChange={(event) => helpers.setValue(event.target.value)}
            >
                <FormControlLabel value="bank" control={<Radio/>} label="Bank Transfer"/>
                <FormControlLabel value="card" control={<Radio/>} label="Credit Card"/>
            </RadioGroup>
            {meta.touched && meta.error ? (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            ) : null}
        </div>
    );
};

const BillingOptionFormSection = () => {
    const [field, meta, helpers] = useField('billingOption');

    return (
        <div>
            <FormLabel id="billing-option-label">Billing Option</FormLabel>
            <RadioGroup
                row
                aria-labelledby="billing-option-label"
                {...field}
                onChange={(event) => helpers.setValue(event.target.value)}
            >
                <FormControlLabel value="company" control={<Radio/>} label="Bill to Company"/>
                <FormControlLabel value="employee" control={<Radio/>} label="Bill to Employee"/>
            </RadioGroup>
            {meta.touched && meta.error ? (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            ) : null}
        </div>
    );
};

const DelegateManagementFormSection = () => {
    const [field, meta, helpers] = useField('delegateManagementOption');

    return (
        <div>
            <FormLabel id="delegate-management-label">Delegate Management</FormLabel>
            <Tooltip
                title="Specify if a delegate will manage travel requests ('Yes' for delegate approval, 'No' for direct booking by employees)"
                placement="right"
            >
                <IconButton size="small" aria-label="information">
                    <InfoIcon fontSize="small"/>
                </IconButton>
            </Tooltip>
            <RadioGroup
                row
                aria-labelledby="delegate-management-label"
                {...field}
                onChange={(event) => helpers.setValue(event.target.value)}
            >
                <FormControlLabel value="yes" control={<Radio/>} label="Yes"/>
                <FormControlLabel value="no" control={<Radio/>} label="No"/>
            </RadioGroup>
            {meta.touched && meta.error ? (
                <Typography variant="caption" style={{color: 'red'}}>{meta.error}</Typography>
            ) : null}
        </div>
    );
};

const ReviewStep = ({formData}) => {
    return (
        <Box pl={3} pr={3}>
            <Typography variant="h6" gutterBottom>Review Your Information</Typography>
            <Box mt={2}>
                <Typography variant="subtitle1">Company Details:</Typography>
                <Typography>
                    <strong>Company Name:</strong> {formData.companyName}
                </Typography>
                <Typography>
                    <strong>Contact Person:</strong> {formData.contactPerson}
                </Typography>
                <Typography>
                    <strong>Contact Email:</strong> {formData.contactEmail}
                </Typography>
                <Typography>
                    <strong>Contact Phone Number:</strong> {formData.phone}
                </Typography>
                <Typography>
                    <strong>Complete Address:</strong> {formData.completeAddress}
                </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="subtitle1">Email Domain(s):</Typography>
                <Typography>
                    <strong>Email Domains:</strong> {formData.emailDomains}
                </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="subtitle1">Billing Information:</Typography>
                <Typography>
                    <strong>Billing Contact Person:</strong> {formData.billingContactPerson}
                </Typography>
                <Typography>
                    <strong>Billing Email:</strong> {formData.billingEmail}
                </Typography>
                <Typography>
                    <strong>Billing Address:</strong> {formData.billingAddress}
                </Typography>
                <Typography>
                    <strong>Payment Method:</strong> {formData.paymentMethod}
                </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="subtitle1">Delegate Management:</Typography>
                <Typography>
                    <strong>Delegate Management:</strong> {formData.delegateManagementOption === 'yes' ? 'Yes' : 'No'}
                </Typography>
            </Box>
            <Box mt={2}>
                <Typography variant="subtitle1">Billing:</Typography>
                <Typography>
                    <strong>Billing Option:</strong> {formData.billingOption === 'company' ? 'Bill to Company' : 'Bill to Employee'}
                </Typography>
            </Box>
            <Box mt={4}>
                <Typography>
                    Review all the information entered for accuracy.
                    Click the "Submit" button to complete your application.
                    You will receive a confirmation email once your request has been submitted. Our team will review your application and get back to you within 24 hours.
                </Typography>
            </Box>
            {/*<Box mt={2}>*/}
            {/*    <Button*/}
            {/*        variant="contained"*/}
            {/*        color="primary"*/}
            {/*        onClick={onSubmit}*/}
            {/*    >*/}
            {/*        Submit Request*/}
            {/*    </Button>*/}
            {/*</Box>*/}
        </Box>    );
};

const StepContent = ({step, formikProps}) => {
    switch (step) {
        case 0:
            return (
                <Box pl={3} pr={3}>
                    <Div sx={{mt: 4, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="companyName"
                            label="Company Name"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Enter the official name of your company as registered."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="contactPerson"
                            label="Contact Person"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Provide the full name of the main point of contact for your company."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="contactEmail"
                            label="Contact Email"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Enter the email address of the contact person. This email will be used for all communications regarding your corporate account."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="phone"
                            label="Contact Phone Number"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Provide a valid phone number where the contact person can be reached."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="completeAddress"
                            label="Complete Address"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Enter the complete physical address of your company, including street address, city, state, and zip code."),
                            }}
                        />
                    </Div>
                </Box>
            );
        case 1:
            return (
                <Box pl={3} pr={3}>
                    <Div sx={{mt: 4, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="emailDomains"
                            label="Email Domain(s)"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Enter the email domain(s) used by your employees. This is necessary for verifying employee email addresses during the sign-up process. For example, if your company email addresses end in '@yourcompany.com', enter 'yourcompany.com'. If your company uses multiple domains, list all applicable domains separated by commas."),
                            }}
                        />
                    </Div>
                </Box>
            );
        case 2:
            return (
                <Box pl={3} pr={3}>
                    <Div sx={{mt: 4, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="billingContactPerson"
                            label="Billing Contact Person"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Provide the name of the billing contact person if different from the main contact person."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="billingEmail"
                            label="Billing Email"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Provide the email address of the billing contact person if different from the main contact person."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <JumboTextField
                            fullWidth
                            name="billingAddress"
                            label="Billing Address"
                            InputProps={{
                                endAdornment: renderTooltipIcon("Provide the billing address if different from the company address."),
                            }}
                        />
                    </Div>
                    <Div sx={{mt: 1, mb: 2}}>
                        <PaymentMethodFormSection/>
                    </Div>
                </Box>
            );
        case 3:
            return (
                <Box pl={3} pr={3}>
                    <Div sx={{mt: 4, mb: 2}}>
                        <DelegateManagementFormSection/>
                    </Div>
                </Box>
            );
        case 4:
            return (
                <Box pl={3} pr={3}>
                    <Div sx={{mt: 4, mb: 2}}>
                        <BillingOptionFormSection/>
                    </Div>
                </Box>
            );
        case 5:
            return (
                <Div sx={{mt: 4, mb: 2}}>
                    <ReviewStep formData={formikProps.values}/>
                </Div>
            );
        default:
            return 'Unknown step';
    }
};

const getValidationSchema = (step) => {
    switch (step) {
        case 0:
            return validationSchemaStep1;
        case 1:
            return validationSchemaStep2;
        case 2:
            return validationSchemaStep3;
        case 3:
            return validationSchemaStep4;
        case 4:
            return validationSchemaStep5;
        case 5:
            return validationSchemaReview; // Use the validation schema for review step
        default:
            return validationSchemaStep1;
    }
};


const BusinessSignupForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const navigate = useNavigate()
    const onSubmit = async (values) => {
        console.log('Form Data',values)
        try {
            let res =  await post('/corporate-signup/v1', values)
            console.log(res)
            toast.loading("Submiting Request...", { id: "req" });
            // await auth.login(email, password);
            toast.success("Request Submitted", { id: "req",  icon: '✅',  // Optional icon
                style: {
                    background: '#4CAF50',  // Green background for success
                    color: 'white',          // White text color
                } });

            // navigate("/");
            // toast.dismiss("auth");
        } catch (e) {
            console.log(e.response)
            if (e.response && e.response.status === 409) {
                toast.error("Contact Email is already registered.", { id: "req" });
            } else {
                toast.error("Something went wrong, please try again.", { id: "req" });
            }        }
    }
    const isLastStep = activeStep === steps.length - 1;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={getValidationSchema(activeStep)}
            onSubmit={async (values, actions) => {
                if (isLastStep) {
                    await onSubmit(values); // Call the onSubmit function with form data
                    actions.setSubmitting(false);
                } else {
                    setActiveStep((prevStep) => prevStep + 1);
                    actions.setTouched({});
                    actions.setSubmitting(false);
                }
            }}
        >
            {({isSubmitting, validateForm, setTouched, errors, ...formikProps}) => (
                <Form>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <StepContent step={activeStep} formikProps={formikProps}/>
                    <Box mt={2} pl={3}>
                        {activeStep > 0 && (
                            <Button
                                onClick={() => {
                                    setActiveStep((prevStep) => prevStep - 1);
                                }}
                            >
                                Back
                            </Button>
                        )}
                        <Button
                            type="button"
                            disabled={isSubmitting}
                            variant="contained"
                            color="primary"
                            onClick={isLastStep ? formikProps.submitForm : async () => {
                                const errors = await validateForm();
                                if (Object.keys(errors).length === 0) {
                                    setActiveStep((prevStep) => prevStep + 1);
                                } else {
                                    setTouched(errors);
                                }

                            }}
                        >
                            {isLastStep ? 'Submit' : 'Next'}
                        </Button>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default BusinessSignupForm;
