import React, {useState} from "react";

import {Button, Grid, Typography} from "@mui/material";
import Datagrid from "app/components/Datagrid";
import {useTranslation} from "react-i18next";
import SearchBar from "../shared/SearchBar";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import HelpIcon from '@mui/icons-material/Help';

import Chip from "@mui/material/Chip";
import CopyWrapper from "../shared/CopyWrapper";
import InspectionModel from "../components/Inspections/InspectionModel"; // Gender-neutral icon
const Inspections = () => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [refetch, setRefetch] = useState(1);

    const handleRefetch = async (data) => {
        setRefetch(refetch + 1)
    };
    const handleOpen = async (data) => {
        setModalData(data)
        setOpen(true);
    };
    const handleClose = async () => {
        setOpen(false);
    };
    const columns = [
        {
            headerName: "ID",
            field: "_id",
            width: 225,
            renderCell: (params) => (
                <Typography variant="body"><CopyWrapper>{params.row?._id}</CopyWrapper></Typography>
            ),
        },
        {
            headerName: "Name",
            field: "driverId.name",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {params.row?.driverId?.name}
                </Typography>
            ),
        }, {
            headerName: "Email",
            field: "driverId.email",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {params.row?.driverId?.email}
                </Typography>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 200,
            renderCell: (params) => {
                const status = params.row?.status;

                // Function to map status values to labels and styles
                const getStatusProps = () => {
                    switch (status) {
                        case 'approved':
                            return {label: "Approved", color: "#4caf50", icon: <CheckCircleIcon/>}; // Green
                        case 'resubmission_requested':
                            return {label: "Resubmission Requested", color: "#ff9800", icon: <AutorenewIcon/>}; // Orange
                        case 'declined':
                            return {label: "Declined", color: "#f44336", icon: <CancelIcon/>}; // Red
                        case 'expired':
                            return {label: "Expired", color: "#9e9e9e", icon: <AccessTimeIcon/>}; // Grey
                        case 'pending':
                            return {label: "Pending", color: "#2196f3", icon: <HourglassEmptyIcon/>}; // Blue
                        case 'not_applied':
                            return {label: "Not Applied", color: "#9e9e9e", icon: <NotInterestedIcon/>}; // Grey
                        default:
                            return {label: "Unknown", color: "#9e9e9e", icon: <HelpIcon/>}; // Default
                    }
                };

                const {label, color, icon} = getStatusProps();

                // Render a Chip for each status
                return (
                    <Chip
                        label={label}
                        icon={icon}
                        style={{
                            backgroundColor: `${color}20`, // Light background
                            color: color, // Text color
                        }}
                        variant="outlined"
                        size="medium"
                    />
                );
            }
        },
        {
            headerName: "Expiry",
            field: "createdAt",
            sortable: false,
            width: 175,
            renderCell: (params) => {
                const expiryDate = params.row?.latestInspection?.expiryDate;

                // Check if the date is valid
                const formattedDate = expiryDate && !isNaN(new Date(expiryDate).getTime())
                    ? new Date(expiryDate).toLocaleString()
                    : '-';

                return formattedDate;
            }
        },
        {
            headerName: "Details",

            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen(params.row,)}
                >
                    Details
                </Button>
            ),
        },

    ];


    const {t} = useTranslation();

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (query) => {
        setSearchQuery(query);
        // Optionally, trigger data fetching or filtering based on the search query
    };
    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" mb={0}>
                <Grid item>
                    <Typography variant="h1">
                        {t("pages.title.inspections")}
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchBar onSearch={handleSearch}/>
                </Grid>

            </Grid>

            {/* Modal  */}
            <InspectionModel open={open} handleClose={handleClose} modalData={modalData} refetch={handleRefetch}/>

            <Datagrid url={'/inspections'} columns={columns} filter={searchQuery} refetch={refetch}/>
        </>
    );
};

export default Inspections;
