import React, { useRef, useState, useEffect } from 'react';
import { Box, Card, CardContent, Typography, Button } from "@mui/material";
import { GoogleMap, LoadScript, Polygon } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../constants";

// Constants for the map container
const mapContainerStyle = {
    width: "100%",
    height: "200px",  // Smaller map height for each card
};

// Memoized GoogleMap to optimize re-renders
const MemoizedGoogleMap = React.memo(({ coordinates, onLoad, onPolygonComplete }) => {
    // Convert the coordinate pairs into lat/lng objects
    const polygonCoordinates = coordinates.map(coord => ({
        lat: coord[1], // lat is the second value in the pair
        lng: coord[0], // lng is the first value in the pair
    }));

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={polygonCoordinates.length ? polygonCoordinates[0] : { lat: 37.0902, lng: -95.7129 }} // Center on the first coordinate
            zoom={4}
            onLoad={onLoad}
            options={{disableDefaultUI:true}}
            onPolygonComplete={onPolygonComplete}
        >
            <Polygon
                paths={polygonCoordinates} // Use the transformed coordinates
                strokeColor="#FF0000"
                strokeOpacity={0.8}
                strokeWeight={2}
                fillColor="#FF0000"
                fillOpacity={0.35}
            />
        </GoogleMap>
    );
});

const PromotionCodeCard = ({ code, onEdit, onDelete }) => {
    const [polygon, setPolygon] = useState(null);
    const mapRef = useRef(null);

    const coordinates = code.polygon?.coordinates || [];

    // Handle the completion of the polygon drawing
    const handlePolygonComplete = (polygon) => {
        const path = polygon.getPath().getArray();
        setPolygon(path);
    };

    // Dynamically zoom map to fit the polygon
    const fitBoundsToPolygon = () => {
        if (mapRef.current && polygon && polygon.length > 0) {
            const bounds = new window.google.maps.LatLngBounds();
            polygon.forEach((latLng) => {
                bounds.extend(latLng);
            });
            mapRef.current.fitBounds(bounds); // Fit map to polygon bounds
        }
    };

    // Effect to fit the bounds once the polygon is available
    useEffect(() => {
        fitBoundsToPolygon();
    }, [polygon]); // Only run when the polygon changes

    return (
        <Card sx={{ width: 300, margin: 2 }}>
            <CardContent>
                {/* Map displayed at the top of the card */}
                <Box mb={2}>
                    {typeof window.google !== 'undefined' ? (
                        <MemoizedGoogleMap
                            coordinates={coordinates}
                            onLoad={(map) => {
                                mapRef.current = map;
                                fitBoundsToPolygon(); // Fit bounds when map loads
                            }}
                            onPolygonComplete={handlePolygonComplete}
                        />
                    ) : (
                        <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing", "places"]}>
                            <MemoizedGoogleMap
                                coordinates={coordinates}
                                onLoad={(map) => {
                                    mapRef.current = map;
                                    fitBoundsToPolygon(); // Fit bounds when map loads
                                }}
                                onPolygonComplete={handlePolygonComplete}
                            />
                        </LoadScript>
                    )}
                </Box>

                {/* Title and Description */}
                <Typography variant="h6">{code.title}</Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {code.description}
                </Typography>

                {/* Other details */}
                <Typography variant="body2" color="textSecondary">
                    <strong>Promo Code:</strong> {code.promoCode}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <strong>Min Fare:</strong> ${code.minFare}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <strong>Discount:</strong> {code.discountPercentage}%
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <strong>Expiry:</strong> {new Date(code.expiryTime).toLocaleString()}
                </Typography>

                {/* Action buttons */}
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <Button onClick={onDelete} variant="outlined" size="small" color="error">
                        Delete
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default PromotionCodeCard;
