import React from 'react';
import {Grid, Typography} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import {useTranslation} from "react-i18next";

const OnlineVisitors = ({title, activeUsers}) => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick

            title={<Typography variant={"h4"}>Online {title}</Typography>}

        >
            <Grid item xs={5} alignSelf={"center"} justifySelf={"center"} mb={4}>
                <Typography variant={"h2"}
                >{activeUsers}</Typography>
                {/*<Typography variant={"body1"} color={"text.secondary"} sx={{whiteSpace: 'nowrap'}} mb={0}>Past 9 months</Typography>*/}
            </Grid>
            {/*<ChartOnlineVisitors/>*/}
        </JumboCardQuick>
    );
};

export default OnlineVisitors;
