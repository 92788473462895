import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { CircularProgress, Typography, Box } from '@mui/material';

const VerifyUserEmail = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const verifyEmailToken = async () => {
            try {
                const response = await axios.post('/verifyUserEmail/v1', { token });
                if (response.status === 200) {
                    setSuccess(true);
                }
            } catch (err) {
                setError('Unable to verify link');
            } finally {
                setLoading(false);
            }
        };

        verifyEmailToken();
    }, [token]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            textAlign="center"
        >
            {loading ? (
                <>
                    <CircularProgress />
                    <Typography variant="h6" marginTop={2}>Verifying your email...</Typography>
                </>
            ) : success ? (
                <>
                    <FaCheckCircle size={80} color="green" />
                    <Typography variant="h6" marginTop={2}>Email verified successfully!</Typography>
                </>
            ) : (
                <>
                    <FaTimesCircle size={80} color="red" />
                    <Typography variant="h6" marginTop={2}>{error}</Typography>
                </>
            )}
        </Box>
    );
};

export default VerifyUserEmail;
