import React from "react";
import {Card, CardContent, Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import {alpha} from "@mui/material/styles";
import {ASSET_IMAGES} from "../utils/constants/paths";
import {getAssetPath} from "../utils/appHelpers";
import * as yup from "yup";
import {Form, Formik} from "formik";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";
import LoadingButton from "@mui/lab/LoadingButton";
import {Link, useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {useAuth} from "app/context/AuthContext";
import {post} from "../utils/httpRequestsHelper";
import BusinessSignupForm from "../components/BusinessSignupForm";

const validationSchema = yup.object({
    email: yup
        .string("Enter your email")
        .email("Enter a valid email")
        .required("Email is required"),
    companyName: yup.string("Enter your Company Name").required("Company Name is required"),
    contactPerson: yup.string("Enter your Contact Person Name").required("Contact Person is required"),
    phone: yup.string("Enter your phone").required("Phone is required"),
});

const BusinessSignup = ({disableSmLogin}) => {
    const navigate = useNavigate();
    const auth = useAuth();


    return (
        <Div
            sx={{
                width: '900px',
                maxWidth: "100%",
                margin: "auto",
                p: 4,
            }}
        >
            <Card
                sx={{
                    display: "flex",
                    minWidth: 0,
                    flexDirection: { xs: "column", md: "row" },
                }}

            >
                <CardContent
                    sx={{
                        flex: "0 1 25%",
                        position: "relative",
                        background: `#0267a0 url(${getAssetPath(
                            `${ASSET_IMAGES}/widgets/keith-luke.jpg`,
                            "640x428"
                        )}) no-repeat center`,
                        backgroundSize: "cover",

                        "&::after": {
                            display: "inline-block",
                            position: "absolute",
                            content: `''`,
                            inset: 0,
                            backgroundColor: alpha("#0267a0", 0.65),
                        },
                    }}
                >
                    <Div
                        sx={{
                            display: "flex",
                            minWidth: 0,
                            flex: 1,
                            flexDirection: "column",
                            color: "common.white",
                            position: "relative",
                            zIndex: 1,
                            height: "100%",
                        }}
                    >
                        <Div sx={{mb: 2}}>
                            <Typography
                                variant={"h3"}
                                color={"inherit"}
                                fontWeight={500}
                                mb={3}
                            >
                                Sign Up
                            </Typography>
                            <Typography variant={"body1"} mb={2}>
                                Welcome to ICE Ride Hailing
                            </Typography> <Typography variant={"body1"} mb={2}>
                            Signup for business account to enjoy corporate benefits
                        </Typography>

                        </Div>

                        {/* <Div sx={{ mt: "auto" }}>*/}
                        {/*  <Link href="#" underline="none" sx={{ display: "inline-flex" }}>*/}
                        {/*    <img src={`${ASSET_IMAGES}/logo-white.png`} alt="Jumbo React" />*/}
                        {/*  </Link>*/}
                        {/*</Div>*/}
                    </Div>
                </CardContent>
                <CardContent sx={{flex: 1, p: 4}}>
                 <BusinessSignupForm/>
                </CardContent>
                {/*<CardContent sx={{flex: 1, p: 4}}>*/}
                {/*    <Formik*/}
                {/*        validateOnChange={true}*/}

                {/*        validationSchema={validationSchema}*/}
                {/*        onSubmit={(data, {setSubmitting}) => {*/}
                {/*            setSubmitting(true);*/}
                {/*            onSignUp(data).then(r => setSubmitting(false));*/}
                {/*        }}*/}
                {/*        initialValues={{*/}
                {/*            email: '',*/}
                {/*            contactPerson: '',*/}
                {/*            companyName: '',*/}
                {/*            phone: ''*/}
                {/*        }}>*/}
                {/*        {({isSubmitting}) => (*/}
                {/*            <Form style={{textAlign: "left"}} noValidate autoComplete="off">*/}
                {/*                <Div sx={{mt: 1, mb: 3}}>*/}
                {/*                    <JumboTextField fullWidth name="companyName" label="Company Name"/>*/}
                {/*                </Div>*/}
                {/*                <Div sx={{mt: 1, mb: 2}}>*/}
                {/*                    <JumboTextField*/}
                {/*                        fullWidth*/}
                {/*                        name="contactPerson"*/}
                {/*                        label="Contact Person"*/}
                {/*                    />*/}
                {/*                </Div> <Div sx={{mt: 1, mb: 2}}>*/}
                {/*                <JumboTextField*/}
                {/*                    fullWidth*/}
                {/*                    name="email"*/}
                {/*                    label="Email"*/}
                {/*                />*/}
                {/*            </Div> <Div sx={{mt: 1, mb: 2}}>*/}
                {/*                <JumboTextField*/}
                {/*                    fullWidth*/}
                {/*                    name="phone"*/}
                {/*                    label="Phone"*/}
                {/*                />*/}
                {/*            </Div>*/}
                {/*                /!* <Div sx={{ mb: 2 }}>*/}
                {/*  <FormControlLabel*/}
                {/*    control={<Checkbox />}*/}
                {/*    label="Remember me"*/}
                {/*  />*/}
                {/*</Div> *!/*/}
                {/*                <LoadingButton*/}
                {/*                    fullWidth*/}
                {/*                    type="submit"*/}
                {/*                    variant="contained"*/}
                {/*                    size="large"*/}
                {/*                    sx={{mb: 3}}*/}
                {/*                    loading={isSubmitting}*/}
                {/*                >*/}
                {/*                    Sign Up*/}
                {/*                </LoadingButton>*/}
                {/*            </Form>*/}
                {/*        )}*/}
                {/*    </Formik>*/}
                {/*    <Link to={"/"} color={"primary"} style={{textDecoration: 'none', color: 'inherit'}}>*/}
                {/*        <Typography variant={"body1"}>*/}
                {/*             Already have an account? Sign In*/}
                {/*        </Typography>*/}
                {/*    </Link>*/}
                {/*</CardContent>*/}
            </Card>
        </Div>
    );
};

export default BusinessSignup;
