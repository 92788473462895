import {Stack, Typography} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React from "react";
import {styled} from "@mui/material/styles";

const Item = styled("div")(({theme}) => ({
    color: theme.palette.common.white,
    marginBottom: 16,
    width: '15.5%',
    [theme.breakpoints.down('lg')]: {
        width: '16.5%',
    },
    [theme.breakpoints.down('md')]: {
        width: '33.3%',
    },
    [theme.breakpoints.down('sm')]: {
        width: '50%',
    },
    padding: theme.spacing(0, 2)
}));

const renderSalesData = (earnings) => {
    return (
        <Stack
            direction={"row"}
            flexWrap={"wrap"}
            sx={{p: theme => theme.spacing(0, 1, 2)}}
        >

            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Total Rides
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings?.ride.count}</Typography>
            </Item>
            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Ride Revenue
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings?.ride.totalAmount}</Typography>
            </Item>
            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Ride Earnings
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings.ride.totalEarnings}</Typography>
            </Item>
            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Total Rentals
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings.rental.count}</Typography>
            </Item>
            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Rental Revenue
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings.rental.totalAmount}</Typography>
            </Item>
            <Item>
                <Typography variant={"body1"} color={"common.white"} mb={1}>
                    <DateRangeIcon fontSize={"small"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/>
                    Rental Earnings
                </Typography>
                <Typography variant={"h6"} color={"common.white"} mb={0}>{earnings.rental.totalEarnings}</Typography>
            </Item>


        </Stack>
    )
};
export default renderSalesData;
