import React from "react";
import ReactDOM from "react-dom";
import App from "./app/App";
import "./app/config/i18n";
import axios from "axios";
import './index.css';
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev"; // Import the CSS file here

axios.defaults.withCredentials = true;

// axios.defaults.baseURL = "http://localhost:3200/adminAPI";
axios.defaults.baseURL = window.location.origin==='http://localhost:3000'?"http://localhost:3200/adminAPI" :"https://beta.backend.iceride.io/adminAPI";


ReactDOM.render(
    <React.StrictMode>

        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <App/>
        </DevSupport>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
