import React, {useState} from "react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Modal,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FileViewer from "../../shared/FIleViewer";
import {fileURL} from "../../context/constants";
import RideList from "../../shared/RideList";
import ComingSoon from "../../shared/ComingSoon";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    width: '50%',
    maxHeight: '70vh',
    overflowY: 'auto'
};

const RiderDetailsModal = ({open, handleClose, modalData, verifyDriver, blockDriver}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    console.log('modalData', modalData)

    return (
        <Modal open={open} onClose={handleClose} sx={{}}>
            <Box sx={{
                padding: 4,
                backgroundColor: "white",
                width: '50%',
                height: '80%',
                margin: "auto",
                mt: 4,
                borderRadius: 2,
                overflow: 'auto'
            }}>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>Rider Details</Typography>
                    <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </Box>

                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Rider Info"/>
                    <Tab label="Documents"/>
                    {/*<Tab label="Earnings"/>*/}
                    <Tab label="Ride History"/>
                    {/*<Tab label="Inspection"/>*/}
                </Tabs>


                <Divider sx={{marginBottom: 4}}
                />

                {activeTab === 0 && (
                    <Grid>
                        {/*<h4>Driver Details</h4>*/}
                        <ProfilePictureWrapper modalData={modalData}/>

                        <Grid>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">Name</Typography>
                                <Typography fontSize="16px">{modalData?.name}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">Email</Typography>
                                <Typography fontSize="16px">{modalData?.email}</Typography>
                            </Stack> <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0"
                                            spacing="20px">
                            <Typography fontSize="16px">Id</Typography>
                            <Typography fontSize="16px">{modalData?._id}</Typography>
                        </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">Phone</Typography>
                                <Typography fontSize="16px">{modalData?.phone}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">City</Typography>
                                <Typography fontSize="16px">{modalData?.city}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">Address</Typography>
                                <Typography fontSize="16px">{modalData?.address}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">State</Typography>
                                <Typography fontSize="16px">{modalData?.state}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">SSN</Typography>
                                <Typography fontSize="16px">{modalData?.socialSecurityNumber}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px">License Number</Typography>
                                <Typography fontSize="16px">{modalData?.licenseNumber}</Typography>
                            </Stack>


                            <Divider/>


                        </Grid>
                        <Grid container columnGap={5} marginBottom="50px">
                            <Typography color="error">{}</Typography>
                        </Grid>
                        <Grid container justifyContent="center">
                            {/*<Button*/}
                            {/*    variant="contained"*/}
                            {/*    disabled={modalData?.isVerified}*/}
                            {/*    sx={{width: "300px"}}*/}
                            {/*    onClick={() => verifyDriver(modalData?._id)}*/}
                            {/*>*/}
                            {/*    {modalData?.isVerified ? "Verified" : "Verify"}*/}
                            {/*</Button> */}
                            <Button
                            color={modalData?.isBlocked ? "success" : "error"}
                            variant="contained"
                            sx={{width: "300px", marginX: '20px'}}
                            onClick={() => blockDriver(modalData?.driverId, !modalData?.isBlocked)}
                        >
                            {modalData?.isBlocked ? "Unblock" : "Block"}
                        </Button>
                        </Grid>
                        {/* Add more fields as needed */}
                    </Grid>
                )}
                {activeTab === 1 && (
                    <Grid>
                        <Box sx={{ padding: 1 }}>

                            <Typography variant="h4" sx={{ marginBottom: 2 }}>
                               Documents
                            </Typography>

                            <DocumentViewer modalData={modalData}/>
                        </Box>


                    </Grid>
                )}
                {/*{activeTab === 2 && (*/}
                {/*    <Grid>*/}
                {/*        /!*<DriverEarnings driverId={modalData._id}/>*!/*/}
                {/*    </Grid>*/}
                {/*)}*/}
                {activeTab === 2 && (
                    <Grid>
                        <RideList id={modalData?._id} driver={false}/>
                    </Grid>
                )}
                {/*{activeTab === 4 && (*/}
                {/*    <Grid>*/}
                {/*        <ComingSoon/>*/}
                {/*    </Grid>*/}
                {/*)}*/}

                {/*<Grid container justifyContent="center" marginTop="20px">*/}
                {/*    <Button*/}
                {/*        variant="contained"*/}
                {/*        disabled={driverDetails?.isVerified}*/}
                {/*        sx={{ width: "300px" }}*/}
                {/*        onClick={() => verifyDriver(driverDetails?._id)}*/}
                {/*    >*/}
                {/*        {driverDetails?.isVerified ? "Verified" : "Verify"}*/}
                {/*    </Button>*/}
                {/*</Grid>*/}
            </Box>
        </Modal>
    );
};

function DocumentSection({label, fileUrl, fallbackMessage}) {
    return (
        <Box mb={2}>
            <Typography variant="h6">{label}</Typography>
            {fileUrl ? (
                <FileViewer fileUrl={fileUrl}/>
            ) : (
                <Typography color="text.secondary">{fallbackMessage}</Typography>
            )}
        </Box>
    );
}

function DocumentViewer({modalData}) {
    return (
        <>
            <DocumentSection
                label="Driving License Front"
                fileUrl={modalData?.drivingLicenseFront}
                fallbackMessage="Driving License Front not available"
            />

            <DocumentSection
                label="Driving License Back"
                fileUrl={modalData?.drivingLicenseBack}
                fallbackMessage="Driving License Back not available"
            />

            <DocumentSection
                label="Insurance Document"
                fileUrl={modalData?.insuranceDocument}
                fallbackMessage="Insurance Document not available"
            />
        </>
    );
}


function ProfilePicture({src}) {
    const [loading, setLoading] = useState(true);

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {loading && (
                <Skeleton
                    variant="circular"
                    width={150}
                    height={150}
                    sx={{justifySelf: 'center'}}
                />
            )}
            <Avatar
                alt="Profile Sharp"
                src={fileURL.concat(src)}
                sx={{
                    width: 150,
                    height: 150,
                    justifySelf: 'center',
                    display: loading ? 'none' : 'block',
                }}
                onLoad={() => setLoading(false)} // Hide skeleton when image loads
            />
        </Box>
    );
}

function ProfilePictureWrapper({modalData}) {
    return modalData?.profilePicture ? (
        <ProfilePicture src={modalData.profilePicture}/>
    ) : (
        <p>Profile Picture not available</p>
    );
}

export default RiderDetailsModal;
