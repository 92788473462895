import { CardHeader } from "@mui/material";
import React, { useState, useEffect } from "react";

export const DashboardCardHeader = ({ name, updateTime }) => {
    const [timeAgo, setTimeAgo] = useState(calculateTimeAgo(updateTime));

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeAgo(calculateTimeAgo(updateTime));
        }, 60000); // Update every minute

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [updateTime]);

    return (
        <CardHeader title={name} subheader={timeAgo} />
    );
}

function calculateTimeAgo(date) {
    const now = new Date();
    const secondsPast = Math.floor((now.getTime() - date) / 1000);

    if (secondsPast < 60) {
        return `updated few seconds ago`;
    }
    if (secondsPast < 3600) {
        const minutes = Math.floor(secondsPast / 60);
        return `updated ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }
    if (secondsPast < 86400) {
        const hours = Math.floor(secondsPast / 3600);
        return `updated ${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    if (secondsPast < 2592000) {
        const days = Math.floor(secondsPast / 86400);
        return `updated ${days} day${days > 1 ? 's' : ''} ago`;
    }
    if (secondsPast < 31536000) {
        const months = Math.floor(secondsPast / 2592000);
        return `updated ${months} month${months > 1 ? 's' : ''} ago`;
    }
    const years = Math.floor(secondsPast / 31536000);
    return `updated ${years} year${years > 1 ? 's' : ''} ago`;
}
