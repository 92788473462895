import { useEffect } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { fileURL as baseUrl } from "../context/constants";

function PdfViewer({ fileUrl, onLoad }) {
    useEffect(() => {
        if (onLoad) {
            onLoad(); // Notify FileViewer that PDF has loaded after initial render
        }
    }, [onLoad]);

    return (
        <Worker workerUrl={`https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js`}>
            <Viewer fileUrl={baseUrl.concat(fileUrl)} />
        </Worker>
    );
}

export default PdfViewer;
