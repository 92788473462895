import React from "react";
import {Box, IconButton, Tooltip, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import toast from "react-hot-toast";

const CopyWrapper = ({children}) => {
    const handleCopy = () => {
        const textToCopy = typeof children === "string" ? children : children.props.children;

        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                toast.success("Text copied to clipboard!",{
                    icon: '✅',  // Optional icon
                    style: {
                        background: '#4CAF50',  // Green background for success
                        color: 'white',          // White text color
                    }
                });
            })
            .catch((err) => {
                toast.error("Failed to copy text");
                console.error("Copy failed:", err);
            });
    };

    return (
        <Box display="inline-flex" alignItems="center" gap={1}>
            <Typography variant="body1" component="span" sx={{wordBreak: "break-all"}}>
                {children??children}
            </Typography>
            <Tooltip title="Copy to clipboard">
                <IconButton size="small" onClick={handleCopy}>
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default CopyWrapper;
