import React, { useEffect, useState } from "react";
import Datagrid from "../components/Datagrid";
import { Button, Grid, Typography, Modal, Box, TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import { fileURL } from "../context/constants";
import { MuiFileInput } from "mui-file-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  minWidth: "400px",
  maxWidth: "600px",
  width: "90vw",
  outline: "none",
};

const IntroScreens = () => {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = useState(false);
  const [screens, setScreens] = React.useState([]);
  const [screenDetails, setScreenDetails] = React.useState();
  const [adding, setAdding] = useState(false);
  const [id, setId] = useState();
  const [order, setOrder] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [heading, setHeading] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [text, setText] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const handleClose = () => setOpen(false);

  const columns = [
    { headerName: "Order", field: "order", sortable: true, width: 100 },
    { field: "heading", headerName: "Heading", width: 150 },
    { field: "text", headerName: "Text", width: 225 },
    {
      headerName: "Image",
      field: "image",
      sortable: true,
      width: 125,
      renderCell: (params) => (
          <a href={`${fileURL}${params.row.image}`} target="_blank" rel="noopener noreferrer">
            <Button variant="outlined" color="success">Image</Button>
          </a>
      ),
    },
    {
      headerName: "Update",
      field: "id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
          <Button
              variant="outlined"
              color="primary"
              onClick={() => handleOpen(params.row)}
          >
            Update
          </Button>
      ),
    },
    {
      headerName: "Delete",
      field: "_id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
          <Button
              variant="outlined"
              color="error"
              onClick={() => handleDelete(params.row._id)}
          >
            Delete
          </Button>
      ),
    },
  ];

  const handleFileChange = (newFile) => {
    setFileError(false);
    setFile(newFile);
  };

  const openAddModal = () => {
    setOrder({ value: "", error: false, helperText: "" });
    setHeading({ value: "", error: false, helperText: "" });
    setText({ value: "", error: false, helperText: "" });
    setFile(null);
    setOpen(true);
    setAdding(true);
  };

  const handleOpen = (data) => {
    setOrder({ value: data.order, error: false, helperText: "" });
    setText({ value: data.text, error: false, helperText: "" });
    setHeading({ value: data.heading, error: false, helperText: "" });
    setAdding(false);
    setId(data._id);
    setOpen(true);
  };

  const handleSubmit = () => {
    if (!order.value) {
      setOrder({ ...order, error: true, helperText: "Provide a valid order" });
      return;
    }
    if (!heading.value) {
      setHeading({ ...heading, error: true, helperText: "Provide a heading" });
      return;
    }
    if (!text.value) {
      setText({ ...text, error: true, helperText: "Provide a text" });
      return;
    }

    let formData = new FormData();
    formData.append("image", file);
    formData.append("order", order.value);
    formData.append("heading", heading.value);
    formData.append("text", text.value);

    const request = adding
        ? axios.post("/addSplashScreen", formData)
        : axios.post("/updateSplashScreen", { ...formData, id });

    const action = adding ? "Adding" : "Updating";

    toast.loading(`${action} Intro Screen`, { id: "screenAction" });
    request
        .then(() => {
          toast.success(`Intro Screen ${adding ? "Added" : "Updated"}`, { id: "screenAction",  icon: '✅',  // Optional icon
            style: {
              background: '#4CAF50',  // Green background for success
              color: 'white',          // White text color
            } });
          handleClose();
        })
        .catch(() => {
          toast.error(`Unable to ${adding ? "add" : "update"} Intro Screen`, { id: "screenAction" });
        });
  };

  const handleDelete = (id) => {
    toast.loading("Deleting Intro Screen", { id: "deleteScreen" });
    axios
        .get(`/deleteSplashScreen/${id}`)
        .then(() => toast.success("Intro Screen Deleted", { id: "deleteScreen" ,  icon: '✅',  // Optional icon
          style: {
            background: '#4CAF50',  // Green background for success
            color: 'white',          // White text color
          }}))
        .catch(() => toast.error("Unable to delete Intro Screen", { id: "deleteScreen" }));
  };

  return (
      <>
        <Typography variant="h1" mb={3}>Intro Screens</Typography>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button variant="contained" onClick={openAddModal} sx={{ marginBottom: '8px' }}>
              Add Intro Screen
            </Button>
          </Grid>
        </Grid>

        <Modal open={open} onClose={handleClose}>
          <Box sx={style}>
            <Grid container justifyContent="space-between" alignItems="center" mb={4}>
              <Typography variant="h5" fontWeight="500">
                {adding ? "Add Intro Screen" : "Update Intro Screen"}
              </Typography>
              <Button sx={{ color: "gray", ":hover": { color: "black" } }} onClick={handleClose}>
                <CloseOutlined />
              </Button>
            </Grid>

            {["order", "heading", "text"].map((field) => (
                <Grid container alignItems="center" spacing={2} key={field} sx={{ mb: 2 }}>
                  <Grid item xs={4}>
                    <Typography variant="body2" fontWeight="500">{field.charAt(0).toUpperCase() + field.slice(1)}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                        size="small"
                        variant="outlined"
                        value={eval(field).value}
                        onChange={(e) => eval(`set${field[0].toUpperCase() + field.slice(1)}`)({
                          ...eval(field),
                          value: e.target.value,
                          error: false,
                        })}
                        helperText={eval(field).helperText}
                        error={eval(field).error}
                        type={field === "order" ? "number" : "text"}
                        fullWidth
                    />
                  </Grid>
                </Grid>
            ))}

            <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="500">Image</Typography>
              </Grid>
              <Grid item xs={8}>
                <MuiFileInput
                    value={file}
                    onChange={handleFileChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                    helperText="Upload an image file"
                    error={fileError}
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center">
              <Button
                  variant="contained"
                  onClick={handleSubmit}
                  sx={{
                    width: "100%",
                    mt: 3,
                    backgroundColor: "primary.main",
                    color: "#fff",
                    ":hover": { backgroundColor: "primary.dark" },
                  }}
              >
                {adding ? "Add Intro Screen" : "Update Intro Screen"}
              </Button>
            </Grid>
          </Box>
        </Modal>

        <Datagrid url="/splashscreens" columns={columns} />
      </>
  );
};

export default IntroScreens;
