import {useEffect, useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";
import {Box, Card, CardContent, LinearProgress, Skeleton, Typography} from "@mui/material";
import ComingSoon from "../../shared/ComingSoon";

export const DriverRewards = ({driverId}) => {
    const [loading, setLoading] = useState(false);
    const [rideStats, setRideStats] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/driver-ride-stats/${driverId}`);
            const data = response.data.data;

            setRideStats(data);
            toast.success("Data Loaded!",{ icon: '✅',  // Optional icon
                style: {
                background: '#4CAF50',  // Green background for success
                    color: 'white',          // White text color
            }});
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to load data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Helper to calculate progress percentage
    const calculateProgress = (count, threshold) => {
        return Math.min((count / threshold) * 100, 100); // Ensure max 100%
    };

    const renderSkeletons = () => (<Box
            sx={{
                display: "flex", flexWrap: "wrap", gap: 3, justifyContent: "center",
            }}
        >
            {Array.from({length: 4}).map((_, index) => (<Card
                    key={index}
                    sx={{
                        width: "250px", boxShadow: 3, borderRadius: 2, textAlign: "center",
                    }}
                >
                    <CardContent>
                        <Skeleton variant="text" width="70%" sx={{mx: "auto", mb: 2}}/>
                        <Skeleton variant="text" width="50%" sx={{mx: "auto", mb: 1}}/>
                        <Skeleton variant="rectangular" height={10} sx={{my: 2, borderRadius: 1}}/>
                        <Skeleton variant="rounded" width="50%" height={36} sx={{mx: "auto"}}/>
                    </CardContent>
                </Card>))}
        </Box>);

    return (<>
            <Box sx={{padding: 0}}>
                {loading ? (renderSkeletons()) : rideStats ? (<>
                        <Typography variant="h5" gutterBottom textAlign="center">
                            Driver Rewards Overview
                        </Typography>
                        <Typography variant="body1" textAlign="center" mb={4}>
                            Total Rides: {rideStats.totalRides}
                        </Typography>


                        <Box
                            sx={{
                                display: "flex", flexWrap: "wrap", gap: 3, justifyContent: "center",
                            }}
                        >
                            {Object.entries(rideStats?.periods).map(([period, stats]) => (<Card
                                    key={period}
                                    sx={{
                                        width: "250px", boxShadow: 3, borderRadius: 2, textAlign: "center",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{textTransform: "capitalize"}}
                                        >
                                            {period.replace(/([A-Z])/g, " $1")} {/* Format camelCase */}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Progress: {stats.count}/{stats.threshold}
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={calculateProgress(stats.count, stats.threshold)}
                                            sx={{
                                                my: 2, height: 8, borderRadius: 1,
                                            }}
                                        />
                                        {/*<Button*/}
                                        {/*    variant="contained"*/}
                                        {/*    size="small"*/}
                                        {/*    disabled={!stats.isEligible}*/}
                                        {/*    onClick={() =>*/}
                                        {/*        toast.success(`${period} reward claimed!`)*/}
                                        {/*    }*/}
                                        {/*    sx={{*/}
                                        {/*        backgroundColor: stats.isEligible*/}
                                        {/*            ? "primary.main"*/}
                                        {/*            : "grey.400",*/}
                                        {/*        color: stats.isEligible ? "#fff" : "#666",*/}
                                        {/*    }}*/}
                                        {/*>*/}
                                        {/*    {stats.isEligible ? "Claim Reward" : "Not Eligible"}*/}
                                        {/*</Button>*/}
                                    </CardContent>
                                </Card>))}
                        </Box>
                    </>) : (<Typography variant="h6" textAlign="center">
                        No data available.
                    </Typography>)}
            </Box>
            <Box sx={{
                padding: 0,
                marginTop: 5
            }}>
                {loading ? (renderSkeletons()) : rideStats ? (<>
                        <Typography variant="h5" gutterBottom textAlign="center">
                            Driver Rewards Claims
                        </Typography>
                    <ComingSoon/>

                    </>) : (<Typography variant="h6" textAlign="center">
                        No Claims available.
                    </Typography>)}
            </Box>
        </>);
};
