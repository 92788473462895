import React, { useRef, useState } from "react";
import { Box, Button, Grid, TextField, Typography, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { DrawingManager, GoogleMap, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import axios from "axios";
import toast from "react-hot-toast";
import PromotionCodesList from "../components/PromotionCodesList";

const MemoizedGoogleMap = React.memo(({ onLoad, onPolygonComplete }) => {
    return (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "500px" }}
            center={{ lat: 37.0902, lng: -95.7129 }}
            zoom={4}
            onLoad={onLoad}
            options={{ scrollwheel: true }}
        >
            <DrawingManager
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: { drawingModes: ["polygon"] },
                    polygonOptions: { editable: true },
                }}
            />
        </GoogleMap>
    );
});

const Promotions = () => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [promoCode, setPromoCode] = useState(""); // New state for promo code
    const [minFare, setMinFare] = useState(""); // New state for min fare
    const [discountPercentage, setDiscountPercentage] = useState(""); // New state for discount percentage
    const [expiryTime, setExpiryTime] = useState(""); // New state for expiry time
    const [polygonPath, setPolygonPath] = useState([]);
    const [titleError, setTitleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [promoCodeError, setPromoCodeError] = useState(""); // Error state for promo code
    const [minFareError, setMinFareError] = useState(""); // Error state for min fare
    const [discountPercentageError, setDiscountPercentageError] = useState(""); // Error state for discount percentage
    const [expiryTimeError, setExpiryTimeError] = useState(""); // Error state for expiry time
    const [polygonError, setPolygonError] = useState("");
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility
    const mapRef = useRef();
    const polygonRef = useRef(null);

    const handlePolygonComplete = (polygon) => {
        if (polygonRef.current) polygonRef.current.setMap(null);
        const path = polygon.getPath().getArray().map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
        setPolygonPath(path);
        polygonRef.current = polygon;
        setPolygonError("");

        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
        mapRef.current.fitBounds(bounds);
    };

    const handleRemovePolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
            setPolygonPath([]);
            setPolygonError("");
        }
    };

    const handleSubmit = async () => {
        let valid = true;

        // Field validations
        if (!title.trim()) {
            setTitleError("Title is required");
            valid = false;
        } else {
            setTitleError("");
        }
        if (!description.trim()) {
            setDescriptionError("Description is required");
            valid = false;
        } else {
            setDescriptionError("");
        }
        if (!promoCode.trim()) {
            setPromoCodeError("Promo code is required");
            valid = false;
        } else {
            setPromoCodeError("");
        }
        if (!minFare || isNaN(minFare) || minFare <= 0) {
            setMinFareError("Min fare must be a positive number");
            valid = false;
        } else {
            setMinFareError("");
        }
        if (!discountPercentage || isNaN(discountPercentage) || discountPercentage < 0 || discountPercentage > 100) {
            setDiscountPercentageError("Discount percentage must be between 0 and 100");
            valid = false;
        } else {
            setDiscountPercentageError("");
        }
        if (!expiryTime) {
            setExpiryTimeError("Expiry time must be a valid time");
            valid = false;
        } else {
            setExpiryTimeError("");
        }
        if (polygonPath.length === 0) {
            setPolygonError("Polygon is required");
            valid = false;
        } else {
            setPolygonError("");
        }

        if (valid) {
            const data = {
                title,
                description,
                promoCode,
                minFare,
                discountPercentage,
                expiryTime,
                coordinates: polygonPath,
            };
            try {
                toast.loading("Submitting...", { id: "driver-alert" });
                const response = await axios.post("/create-promotion", data);

                if (response.status === 201) {
                    toast.success("Promo created", { id: "driver-alert" ,  icon: '✅',  // Optional icon
                        style: {
                            background: '#4CAF50',  // Green background for success
                            color: 'white',          // White text color
                        }});
                    // Reset form and close modal
                    setTitle("");
                    setDescription("");
                    setPromoCode("");
                    setMinFare("");
                    setDiscountPercentage("");
                    setExpiryTime(""); // Reset expiry time
                    setPolygonPath([]);
                    handleRemovePolygon();
                    setOpenModal(false); // Close modal after successful submission
                } else {
                    toast.error("Error saving data", { id: "driver-alert" });
                }
            } catch (error) {
                toast.error("Error saving data", { id: "driver-alert" });
            }
        }
    };

    return (
    <>    <Box>
        <Typography variant="h1" mb={3} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            Promo Codes
            <Button variant="contained" color="primary" onClick={() => setOpenModal(true)}>
                Create
            </Button>
        </Typography>

        <PromotionCodesList/>
    </Box>
        <Dialog open={openModal} onClose={() => setOpenModal(false)} fullWidth>
            <DialogTitle>Create Promo Code</DialogTitle>
            <DialogContent>
                <Grid container columnSpacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!titleError}
                            helperText={titleError}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Promo Code"
                            value={promoCode}
                            onChange={(e) => setPromoCode(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!promoCodeError}
                            helperText={promoCodeError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Min Fare"
                            value={minFare}
                            onChange={(e) => setMinFare(e.target.value)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 0.01, // Ensures increments in steps of 0.01
                            }}
                            error={!!minFareError}
                            helperText={minFareError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Discount Percentage"
                            value={discountPercentage}
                            onChange={(e) => setDiscountPercentage(e.target.value)}
                            fullWidth
                            margin="normal"
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 0.01,
                                max: 100, // Ensures increments in steps of 0.01
                            }}
                            error={!!discountPercentageError}
                            helperText={discountPercentageError}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Expiry Time "
                            value={expiryTime}
                            onChange={(e) => setExpiryTime(e.target.value)}
                            fullWidth
                            margin="normal"
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true, // This keeps the label above the field when a value is present
                            }}
                            error={!!expiryTimeError}
                            helperText={expiryTimeError}
                        />
                    </Grid>

                    <Grid item xs={9}>
                        <TextField
                            label="Description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={!!descriptionError}
                            helperText={descriptionError}
                            autoComplete
                        />
                    </Grid>
                </Grid>

                {typeof window.google !== "undefined" ? (
                    <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)} onPolygonComplete={handlePolygonComplete} />
                ) : (
                    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                        <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)} onPolygonComplete={handlePolygonComplete} />
                    </LoadScript>
                )}
                {polygonError && <Typography color="error">{polygonError}</Typography>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenModal(false)} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} variant="contained" color="primary">
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    </>
);
};

export default Promotions;
