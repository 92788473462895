import { useState } from 'react';
import { Box } from '@mui/material';
import { fileURL } from "../context/constants";

function ImageViewer({ src, alt, onLoad }) {
    const [loading, setLoading] = useState(true);

    return (
        <Box
            component="img"
            sx={{
                width: '80%',
                height: '400px',
                objectFit: 'cover',
                display: loading ? 'none' : 'block',
                justifySelf: 'center',
                borderRadius: '10px',
            }}

            src={fileURL.concat(src)}
            alt={alt}
            onLoad={() => {
                setLoading(false);
                if (onLoad) onLoad(); // Notify FileViewer that image has loaded
            }}
        />
    );
}

export default ImageViewer;
