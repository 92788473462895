import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, Skeleton, Typography} from "@mui/material";
import SalesStatistics from "../shared/metrics/SalesStatistics";
import AppUsers from "../shared/metrics/AppUsers";
import axios from "axios";
import toast from "react-hot-toast";
import DriverMap from "../shared/DashBoard/DriverMap";
import SalesReport from "../shared/metrics/SalesReport";
import YearlyProfileReport from "../shared/metrics/YearlyProfitReport";
import OrdersReport from "../shared/metrics/OrdersReport";
import MarketingCampaign from "../shared/widgets/MarketingCampaign";
import LatestAlerts from "../shared/widgets/LatestAlerts";
import SiteVisitors from "../shared/widgets/SiteVisitors";
import LastMonthSales from "../shared/metrics/LastMonthSales";
import OnlineSignupsFilled from "../shared/metrics/OnlineSignupsFilled";
import NewVisitorsThisMonth from "../shared/metrics/NewVisitorsThisMonth";
import TotalRevenueThisYear from "../shared/metrics/TotalRevenueThisYear";
import OnlineVisitors from "../shared/metrics/OnlineVisitors";

const Dashboard = () => {
    const [data, setData] = useState()
    const [updateTime, setUpdateTime] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                const response = await axios.get('/dashboard-data/v1', {});
                if (response.status === 200) {
                    // console.log(response.data);
                    setData(response.data);
                    setUpdateTime(Date.now())
                }
            } catch (err) {
                setLoading(false);
                toast.error('Unable to fetch Data')
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);
    return (
        <>
            {
                loading ?
                    (<>
                        <>
                            {/* Skeleton for SalesStatistics */}
                            <Grid container spacing={3.75}>
                                <Grid item xs={12}>
                                    <Skeleton variant="rectangular" width="100%" height={250} />
                                    <Skeleton variant="text" width="30%" />
                                    <Skeleton variant="text" width="50%" />
                                    <Skeleton variant="text" width="60%" />
                                </Grid>

                                {/* Skeleton for Online Visitors */}
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="rectangular" width="100%" height={100} />
                                </Grid>

                                {/* Skeleton for SalesReport */}
                                <Grid item xs={12} md={6}>
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="rectangular" width="100%" height={250} />
                                </Grid>

                                {/* Skeleton for AppUsers */}
                                <Grid item xs={12} sm={6} lg={3}>
                                    <Skeleton variant="text" width="80%" />
                                    <Skeleton variant="rectangular" width="100%" height={100} />
                                </Grid>

                                {/* Skeleton for DriverMap */}
                                <Grid item xs={12}>
                                    <Skeleton variant="rectangular" width="100%" height={400} />
                                </Grid>
                            </Grid>
                        </>
                    </>)
                    :
                    (<Grid container spacing={3.75}>
                        <Grid item xs={12}>
                            <SalesStatistics regByMonth={data?.regByMonth} ridesByMonth={data?.ridesByMonth}
                                             updateTime={updateTime} earnings={data?.earnings}/>
                        </Grid>


                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <YearlyProfileReport/>*/}
                        {/*</Grid>*/}

                        <Grid item xs={12} sm={6} lg={3}>
                            <OnlineVisitors title="Users" activeUsers={data?.activeUsers?.totalUsers}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <OnlineVisitors title="Drivers" activeUsers={data?.activeUsers?.totalDrivers}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SalesReport earningsByMonth={data?.monthlyEarnings}  totalEarining={data?.totalEarnings} updateTime={updateTime}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                            <AppUsers totalUsers={data?.iosUsers} androidUsers={data?.androidUsers}
                                      updateTime={updateTime}/>
                        </Grid>

                        <Grid item xs={12}>
                            <DriverMap/>
                        </Grid>

                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OrdersReport subTitle={null} chartHeight={183}/>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <MarketingCampaign scrollHeight={428}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <LatestAlerts scrollHeight={385}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12}>*/}
                        {/*     <SiteVisitors />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <LastMonthSales/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OnlineSignupsFilled/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <NewVisitorsThisMonth/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <TotalRevenueThisYear/>*/}
                        {/*</Grid>*/}
                    </Grid>)
            }
        </>

    );
}
export default Dashboard;
