import React from 'react';
import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import Div from "@jumbo/shared/Div";
import {useTranslation} from "react-i18next";
import LineChartSales from "../SalesStatistics/LineChartSales";

const SalesReport = ({earningsByMonth,totalEarining, updateTime}) => {
    const {t} = useTranslation();
    return (
        <JumboCardQuick
            title={<Typography variant={"h4"} mb={0}>{t('widgets.title.salesReports')}</Typography>}
            wrapperSx={{pt: 0,}}

        >
            <Grid container columnSpacing={1}>
                <Grid item xs={3} alignSelf={"center"}>
                    <Typography variant={"h2"}>${totalEarining}+</Typography>
                    {/*<Typography variant={"body1"} color={"text.secondary"} sx={{whiteSpace: 'nowrap'}} mb={0}>Past 9*/}
                    {/*    months</Typography>*/}
                </Grid>
                <Grid item xs={8}>
                    <Div sx={{my: '-2px'}}>
                        {/*<SalesReportChart/>*/}
                        <LineChartSales data={earningsByMonth} name={'Signups'} updateTime={updateTime}/>

                    </Div>
                </Grid>
            </Grid>
        </JumboCardQuick>
    );
};

export default SalesReport;
