import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import {Skeleton} from "@mui/material";

const AuthWrapper = () => {
    const auth = useAuth();
    const location = useLocation();

    if (auth.loading) {
        return (
            <div style={{padding: "20px"}}>
                <Skeleton variant="rectangular" height={40} width="100%"/>
                <Skeleton variant="rectangular" height={40} width="100%" style={{marginTop: 10}}/>
                <Skeleton variant="rectangular" height={40} width="100%" style={{marginTop: 10}}/>
            </div>
        )
    }

    return auth.isLoggedIn ? <Outlet/> : <Navigate to="/" state={{from: location}} replace/>;
};

export default AuthWrapper;
